import axios from 'axios';
import config from '../config/config';
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import polygonapi from "../ABI/polytrustABI";
import { toastAlert } from "../helper/toastAlert";
import store from "../store";

export const get_levelcounts = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getlevelcount`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const getstakehistory = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/stakehistory`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
};

export const get_planAlist = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/planAlist`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const updatehistory = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/updatehistory`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const getHeaderdetails = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getHeaderdetails`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const get_lastesttrx = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.Server_URL}api/getlasttransaction`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {

  }
}

export const getCurAddr = async () => {
  try {
    var reduxVal = store.getState();
    var transfort = (reduxVal && reduxVal.walletConnection && reduxVal.walletConnection && reduxVal.walletConnection.web3 && reduxVal.walletConnection.web3.key) ? reduxVal.walletConnection.web3 : {}
    console.log(transfort, "=====transforttransfort")
    if (transfort && transfort != null && transfort != undefined && transfort != "") {
      var web3 = new Web3(transfort)
      var network = await web3.eth.net.getId();
      console.log(network, "=======nenenennene")
      var result = await web3.eth.getAccounts();
      let bnbbalance = await web3.eth.getBalance(result[0]);
      if (network == config.networkVersion) {
        var polygonContract = new web3.eth.Contract(polygonapi, config.polytrustcontract);
        let userdetails = await polygonContract.methods.users(result[0]).call();
        localStorage.setItem("account", result[0]);
        var data = {
          address: result[0],
          provider: transfort,
          polygonvalue: bnbbalance,
          userdetails: userdetails
        }
        return data
      } else {
        toastAlert('error', "Please select Matic chain network", 'network');
        localStorage.clear();
      }

    } else {
      
    }
  } catch (err) {
    console.log(err, 'mmmmmmmmmmmmmmmm')
    
  }
}

export const getCurAddrold = async () => {
  if (localStorage.getItem("wallettype") && localStorage.getItem("wallettype") != null && localStorage.getItem("wallettype") != undefined && localStorage.getItem("wallettype") != "" && localStorage.getItem("wallettype") == "trust") {
    try {
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          [config.chainId]: config.rpcURL
        },
        chainId: config.chainId

      });

      provider.on("connect", () => {
      });
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (result[0] != undefined) {
        if (config.chainId == network) {
          localStorage.setItem("wallettype", "trust")
          localStorage.setItem("account", result[0]);
          let polygonbalance = await web3.eth.getBalance(result[0]);
          console.log('polygonbalancwwwwwwwwwwwwwwwwwwwwwe: ', polygonbalance);
          var polygonContract = new web3.eth.Contract(polygonapi, config.polytrustcontract);
          let userdetails = await polygonContract.methods.users(result[0]).call();
          console.log('userdetailsaaaaaa: ', userdetails);
          var data = {
            address: result[0],
            provider: provider,
            polygonvalue: polygonbalance,
            userdetails: userdetails
          }
          return data
        } else {
          await provider.disconnect();
        }
      }
    } catch (err) {
      localStorage.clear();
      window.location.reload()
    }
  }
  else {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum.enable()
          let web3 = new Web3(window.ethereum);
          if (window.ethereum) {
            let netid = await web3.eth.net.getId();
            console.log(netid, "==========getCurAddrgetCurAddrgetCurAddr")
            if (
              netid ==
              config.networkVersion
            ) {
              var result = await web3.eth.getAccounts();
              let polygonbalance = await web3.eth.getBalance(result[0]);
              var polygonContract = new web3.eth.Contract(polygonapi, config.polytrustcontract);
              let userdetails = await polygonContract.methods.users(result[0]).call();
              if (localStorage.getItem("account")) {
                localStorage.setItem("wallettype", "metamask")
                localStorage.setItem("account", result[0]);
              }
              var data = {
                address: result[0],
                polygonvalue: polygonbalance,
                userdetails: userdetails
              }
              console.log(data, "======daa")
              return data
            }
          }
        }
      } catch (err) {
        localStorage.clear();
        console.log(err, "===========errrrrrrrrrrrdata")
      }
    }
  }
}