import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import Aboveinfo from "../components/aboveinfo.js";
import { setWallet } from "../reducers/Actions";
import { type WalletClient, useWalletClient } from 'wagmi'
import { BrowserProvider, JsonRpcSigner } from 'ethers'
import { useSelector, useDispatch } from "react-redux";
import config from "../config/config";
import stakeabi from "../ABI/stakeABI";
import polygonapi from "../ABI/polytrustABI";
import { toastAlert } from "../helper/toastAlert";
import BigNumber from "bignumber.js";
import moment from "moment";
import Web3 from "web3";
import { getCurAddr , getstakehistory , updatehistory } from "../action/Apicontroller";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";
import syncEach from "sync-each";


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Staking(props) {

  const [depositamt,setdepositamt]  = useState(0);
  const [historylist,sethistorylist]= useState(0);
  const [progress,setprogress]= useState(false);
  const [withdrawprogress,setwithdrawprogress]= useState(false);
  const [availablePending,setavailablePending]= useState(0);
  const [usertotalDeposit,setusertotalDeposit]= useState(0);
  let baseinfo = {
              "sitetotalearn":0,
              "sitetotalDeposit":0,
              "sitetotaluser": 0,
              "maxinv": 0,
              "mininv":0,
              "rewardpercent":0,
              "adminfee":0,
            }
  const [basicinfo,setbasicinfo]= useState(baseinfo);
  let initialvalue = {
              "isExist":false,
              "id":0,
              "currentAmount":0,
              "earnedAmount":0,
              "totalearnedAmount": 0,
              "activeTimestamp":0,
              "isClaim":true,
              "balance": 0,
              "availablePending":0
            }
  const [userdetails,setuserdetails]= useState(initialvalue);
  const dispatch = useDispatch()
  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })

  useEffect(() => {
    getdetails();
    gethistory();
    basicdetails();
  }, [walletClient]);

  async function gethistory(){
    try{
      let list = await getstakehistory();
      console.log(list.data.data,"=====ilsisnhsb")
      if(list && list.data && list.data.data){
        sethistorylist(list.data.data);
      }
    }catch(err){

    }
  }

  async function basicdetails(){
      try{
        let web3 = new Web3(config.rpcURL);
        const multicall = new Multicall({
          web3Instance: web3,
        });
            var CallContext = [
              {
                reference: "details",
                contractAddress: config.stakecontract,
                abi: stakeabi,
                calls: [
                 
                  {
                    reference: "minInvestment",
                    methodName: "minInvestment",
                    methodParameters: [],
                  },
                  {
                    reference: "maxInvestment",
                    methodName: "maxInvestment",
                    methodParameters: [],
                  },
                  {
                    reference: "totalUsers",
                    methodName: "totalUsers",
                    methodParameters: [],
                  },
                  {
                    reference: "totalDeposit",
                    methodName: "totalDeposit",
                    methodParameters: [],
                  },
                  {
                    reference: "totalEarn",
                    methodName: "totalEarn",
                    methodParameters: [],
                  },
                  {
                    reference: "rewardPercent",
                    methodName: "rewardPercent",
                    methodParameters: [],
                  },
                  {
                    reference: "adminFee",
                    methodName: "adminFee",
                    methodParameters: [],
                  },
                ],
              },
            ];
            const results = await multicall.call(CallContext);
            var stakeContract = new web3.eth.Contract(stakeabi, config.stakecontract);
            var minInvestment = await getFormatMulticall(results,"minInvestment",0);
            var maxInvestment = await getFormatMulticall(results,"maxInvestment",0);
            var totalUsers = await getFormatMulticall(results,"totalUsers",0);
            var totalDeposit = await getFormatMulticall(results,"totalDeposit",0);
            var totalEarn = await getFormatMulticall(results,"totalEarn",0);
            var rewardPercent = await getFormatMulticall(results,"rewardPercent",0);
            var adminFee = await getFormatMulticall(results,"adminFee",0);
            let _rewardPercent = await bignumber(rewardPercent[0]);
            let _adminFee = await bignumber(adminFee[0]);
            let _totalEarn = await bignumber(totalEarn[0]);
            let _totalDeposit = await bignumber(totalDeposit[0]);
            let _totalUsers = await bignumber(totalUsers[0]);
            let _maxInvestment = await bignumber(maxInvestment[0]);
            let _minInvestment = await bignumber(minInvestment[0]);
            
            let details = {
              "sitetotalearn":_totalEarn,
              "sitetotalDeposit":_totalDeposit,
              "sitetotaluser": _totalUsers,
              "maxinv": _maxInvestment,
              "mininv":_minInvestment,
              "rewardpercent":_rewardPercent,
              "adminfee":_adminFee,
            }
            setbasicinfo(details);
      }
      catch(err){
        console.log(err,"====errrrrerere");
      }
  }

  async function getdetails(){
    setdepositamt(0);
     let datas = await getCurAddr();
      try{
        if (
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != ""
        ) {
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );
        const multicall = new Multicall({
          web3Instance: web3,
        });
          if(datas && datas.userdetails && datas.userdetails.isExist){
            var CallContext = [
              {
                reference: "details",
                contractAddress: config.stakecontract,
                abi: stakeabi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [datas.address],
                  },
                  {
                    reference: "checkUserRejoin",
                    methodName: "checkUserRejoin",
                    methodParameters: [datas.address],
                  },
                  {
                    reference: "minInvestment",
                    methodName: "minInvestment",
                    methodParameters: [],
                  },
                  {
                    reference: "maxInvestment",
                    methodName: "maxInvestment",
                    methodParameters: [],
                  },
                  {
                    reference: "totalUsers",
                    methodName: "totalUsers",
                    methodParameters: [],
                  },
                  {
                    reference: "totalDeposit",
                    methodName: "totalDeposit",
                    methodParameters: [],
                  },
                  {
                    reference: "totalEarn",
                    methodName: "totalEarn",
                    methodParameters: [],
                  },
                  {
                    reference: "rewardPercent",
                    methodName: "rewardPercent",
                    methodParameters: [],
                  },
                  {
                    reference: "adminFee",
                    methodName: "adminFee",
                    methodParameters: [],
                  },
                ],
              },
            ];
            const results = await multicall.call(CallContext);
            var stakeContract = new web3.eth.Contract(stakeabi, config.stakecontract);
            let getUserDeposittime = await stakeContract.methods.getUserDeposittime(datas.address).call();
            let getUserDepositAmount = await stakeContract.methods.getUserDepositAmount(datas.address).call();      
            var userprofile = await getFormatMulticall(results,"userprofile",0);
            var checkUserRejoin = await getFormatMulticall(results,"checkUserRejoin",0);
            var minInvestment = await getFormatMulticall(results,"minInvestment",0);
            var maxInvestment = await getFormatMulticall(results,"maxInvestment",0);
            var totalUsers = await getFormatMulticall(results,"totalUsers",0);
            var totalDeposit = await getFormatMulticall(results,"totalDeposit",0);
            var totalEarn = await getFormatMulticall(results,"totalEarn",0);
            var rewardPercent = await getFormatMulticall(results,"rewardPercent",0);
            var adminFee = await getFormatMulticall(results,"adminFee",0);
            let _rewardPercent = await bignumber(rewardPercent[0]);
            let _adminFee = await bignumber(adminFee[0]);
            let _totalEarn = await bignumber(totalEarn[0]);
            let _totalDeposit = await bignumber(totalDeposit[0]);
            let _totalUsers = await bignumber(totalUsers[0]);
            let _maxInvestment = await bignumber(maxInvestment[0]);
            let _minInvestment = await bignumber(minInvestment[0]);
            let _isexist = userprofile[0];
            let _id = userprofile[1];
            let _currentAmount = await bignumber(userprofile[2]);
            let _earnedAmount = await bignumber(userprofile[3]);
            let _totalearnedAmount = await bignumber(userprofile[4]);
            let _activeTimestamp = await bignumber(userprofile[5]);
            //let _availablePending = await bignumber(_availablePending[0]);
            let _isClaim = userprofile[6];
            let details = {
              "sitetotalearn":_totalEarn,
              "sitetotalDeposit":_totalDeposit,
              "sitetotaluser": _totalUsers,
              "maxinv": _maxInvestment,
              "mininv":_minInvestment,
              "isExist":_isexist,
              "id":_id,
              "checkUserRejoin":checkUserRejoin[0],
              "currentAmount":_currentAmount,
              "earnedAmount":_earnedAmount,
              "totalearnedAmount": _totalearnedAmount,
              "activeTimestamp":_activeTimestamp,
              "isClaim":_isClaim,
              "rewardpercent":_rewardPercent,
              "adminfee":_adminFee,
              "balance": datas.polygonvalue,
              "deposittime": getUserDeposittime,
              "depositamount": getUserDepositAmount
            }
            setuserdetails(details);
            console.log(details,"====details,")
            let availablePending = await stakeContract.methods.availablePending(datas.address).call(); 
            setavailablePending(availablePending);
            let mydep = getUserDepositAmount.reduce((accumulator, currentValue) => {
              return parseFloat(accumulator) + parseFloat(currentValue)
            },0);
            setusertotalDeposit(mydep);     
          }else{
            toastAlert('error', "Join to proceed Further", 'network');
            window.location.href = "/join-now"
          }
        }else{


        }
      }
      catch(err){
        console.log(err,"====errrrrerere");
      }
  }

  async function deposit(){
    setprogress(true);
    console.log(userdetails,"===userdetailsuserdetails")
    let datas = await getCurAddr();
    try{
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != ""
      ) {
        let web3 = new Web3(
          datas &&
          datas.provider &&
          datas.provider != null &&
          datas.provider != undefined &&
          datas.provider != ""
            ? datas.provider
            : window.ethereum
        );
         if(datas.userdetails.isExist){
          if(datas.userdetails.currentLevel>=2){
            if(depositamt && depositamt!=null && depositamt!=undefined && depositamt!="" && parseFloat(depositamt)>0){
              let dep_amt = depositamt * 1e18;
              if(dep_amt>=basicinfo.mininv){
                if(dep_amt<=basicinfo.maxinv){
                  if(userdetails.balance > dep_amt){
                    if((!userdetails.isExist) || (userdetails.isExist && userdetails.checkUserRejoin) ){
                      dep_amt =await convert(dep_amt);
                      var stakeContract = new web3.eth.Contract(stakeabi, config.stakecontract);
                      let transaction = userdetails.isExist ? 
                                          await stakeContract.methods.reJoin().send({from:datas.address,value:parseFloat(dep_amt).toString()})
                                        :
                                          await stakeContract.methods.regUser().send({from:datas.address,value:(dep_amt).toString()})
                      if (transaction && transaction.status && transaction.transactionHash) {
                        let dats = {
                          "address" : datas.address,
                          "amount": dep_amt,
                          "date":moment().unix(),
                          "type":userdetails.isExist?2:1,
                          "hash":transaction.transactionHash
                        }
                        await updatehistory(dats);
                        toastAlert('success', "Successfully Done", 'success');
                        setprogress(false);
                        window.location.reload();
                      }else{
                        setprogress(false);
                        toastAlert('error', "Transaction Failed, Try again Later", 'network');
                      }
                    }else{
                      setprogress(false);
                      toastAlert('error', "You are not eligible to Rejoin Now", 'network');
                    }
                  }else{
                    setprogress(false);
                    toastAlert('error', "Insufficient Balance for Transaction", 'network');
                  }
                }else{
                  setprogress(false);
                  let text = "Maximum Deposit Limit :"+parseFloat(basicinfo.maxinv)/1e18
                  toastAlert('error', text , 'network');
                }
              }else{
                setprogress(false);
                let text = "Minimum Deposit Limit :"+parseFloat(basicinfo.mininv)/1e18
                toastAlert('error', text , 'network');
              }
            }else{
              setprogress(false);
              toastAlert('error', "Please Enter Valid Amount", 'network');
            }
          }else{
            setprogress(false);
            toastAlert('error', "Upgrade Level 2 to Proceed Further", 'network');
          }
         }else{
           setprogress(false);
            toastAlert('error', "Join Now to proceed Further", 'network');
            window.location.href="/join-now";
         }
      }else{
        setprogress(false);
        toastAlert('error', "Connect Wallet to proceed Further", 'network');

      }
    }catch(err){
      setprogress(false);
      console.log(err)
      toastAlert('error', "Process Cancelled", 'network');
    }
  }

  async function withdraw(){
    setwithdrawprogress(true);
    let datas = await getCurAddr();
    try{
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != ""
      ) {
        let web3 = new Web3(
          datas &&
          datas.provider &&
          datas.provider != null &&
          datas.provider != undefined &&
          datas.provider != ""
            ? datas.provider
            : window.ethereum
        );
         if(datas.userdetails.isExist){
          if(datas.userdetails.currentLevel>=2){
            if(availablePending && availablePending!=null && availablePending!=undefined && availablePending!="" && parseFloat(availablePending)>0){
              
                    if(userdetails && !userdetails.isClaim){
                      var stakeContract = new web3.eth.Contract(stakeabi, config.stakecontract);
                      let transaction = await stakeContract.methods.claimReward().send({from:datas.address})
                      toastAlert('success', "Successfully Done", 'success');
                      setwithdrawprogress(false);
                      window.location.reload();
                    }else{
                      setwithdrawprogress(false);
                      toastAlert('error', "You are not eligible to Claim Now", 'network');
                    }
                  
               
            }else{
              setwithdrawprogress(false);
              toastAlert('error', "Insufficient Balance to Withdraw", 'network');
            }
          }else{
            setwithdrawprogress(false);
            toastAlert('error', "Upgrade Level 2 to Proceed Further", 'network');
          }
         }else{
            setwithdrawprogress(false);
            toastAlert('error', "Join Now to proceed Further", 'network');
            window.location.href="/join-now";
         }
      }else{
        setwithdrawprogress(false);
        toastAlert('error', "Connect Wallet to proceed Further", 'network');

      }
    }catch(err){
      setwithdrawprogress(false);
      console.log(err)
      toastAlert('error', "Process Cancelled", 'network');
    }
  }

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  function getFormatMulticall(results, name, pos) {
    try {
      var index =
        results &&
        results.results &&
        results.results.details &&
        results.results.details.callsReturnContext &&
        results.results.details.callsReturnContext.findIndex(
          (val) => val.reference == name
        );
      var returnVal =
        results.results.details.callsReturnContext[index] &&
        results.results.details.callsReturnContext[index].returnValues
          ? results.results.details.callsReturnContext[index].returnValues
          : "";
      return returnVal;
    } catch (err) {
      console.log(err, "==+++++++");
      return "";
    }
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }



  return (
    <div>
      <ScrollToTopOnMount />
      <Aboveinfo />
      <Navbar />
      <div className="staking_wrapper pb-0">
        <div className="ct-particle-animate">
          <div id="ct_particle_animate-a461fd71" className="shape-animate1">
            <img
              width="102"
              height="104"
              src={require("../assets/images/bg_token_01.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd72" className="shape-animate2">
            <img
              width="104"
              height="102"
              src={require("../assets/images/bg_token_02.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd73" className="shape-animate3">
            <img
              width="82"
              height="81"
              src={require("../assets/images/bg_token_03.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd74" className="shape-animate4">
            <img
              width="60"
              height="59"
              src={require("../assets/images/bg_token_04.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
          <div id="ct_particle_animate-a461fd75" className="shape-animate5">
            <img
              width="102"
              height="104"
              src={require("../assets/images/bg_token_01.png")}
              className="img-fluid"
              alt="Shape"
            />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Deposit MATIC and receive <span>rewards 102% in 24 Hours</span></h2>
            </div>
          </div>
          <div className="row">
              <div className="col-md-11 mx-auto">
          <div className="row staking_box_row">
            <div className="col-lg-6">
              <div className="staking_box">
                <h3>Deposit</h3>
                <div className="staking_box_top">                  
                  <div>
                    <input className="form-control primary_inp" placeholder="Amount" type="number" min="10" onChange={(e)=>setdepositamt(e.target.value)} value = {depositamt}/>
                    <p className="stake_deposit_note">{"Min deposit is "+ (parseFloat(basicinfo.mininv)/1e18) +" MATIC"}</p>
                  </div>
                  {progress ? 
                      <button type="button" class="butn">Processing..</button>
                    : ((!userdetails.isExist) || (userdetails.isExist && userdetails.checkUserRejoin)) && 
                      <button type="button" class="butn" onClick={()=>deposit()}>{userdetails && userdetails.isExist ? "ReJoin" : "Deposit"}</button>
                  }
                </div>
                <div className="staking_box_bottom">
                  <h3>Desricption:</h3>
                  <p>{"Get "+basicinfo.rewardpercent +" % ROI in 24 Hours. Minimum Desposit "+ (parseFloat(basicinfo.mininv)/1e18)  + " MATIC and Maximum Deposit "+(parseFloat(basicinfo.maxinv)/1e18) +" MATIC."}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="staking_box">
                <h3>Dashboard</h3>
                <div className="staking_box_top">                  
                  <div>
                    <p className="bal_label">Your Available Balance</p>
                    <h4>{userdetails && userdetails.isClaim ? "0 MATIC" : (parseFloat(availablePending)/1e18).toFixed(7)+" MATIC" }</h4>
                    {/*<p className="bal_dollar">$ 0.000</p>*/}
                    <p className="bal_dollar">{"Withdraw Fee :"+ basicinfo.adminfee +" %"}</p>
                     
                  </div>
                  {withdrawprogress ?
                      <button type="button" class="butn" >Processing..</button>
                    :
                      <button type="button" class="butn" onClick={()=>withdraw()}>Withdraw</button>

                  }
                </div>
                <div className="staking_box_bottom staking_box_bottom_flex">
                  <div>
                    <h3>Total Deposit</h3>
                    <p>{(parseFloat(usertotalDeposit)/1e18).toFixed(5)} MATIC</p>
                  </div>
                  <div>
                    <h3>Total Withdraw</h3>
                    <p>{(parseFloat(userdetails.totalearnedAmount)/1e18).toFixed(4)} MATIC</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row stake_info_box">
            <div className="col-md-4 d-flex">
              <div className="box w-100">
              <h5>Total Users</h5>
              <h6>{basicinfo.sitetotaluser}</h6>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="box w-100 blue">
              <h5>Total Earn</h5>
              <h6>{(parseFloat(basicinfo.sitetotalearn)/1e18).toFixed(4)} MATIC</h6>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="box w-100 violet">
              <h5>Total Deposit</h5>
              <h6>{(parseFloat(basicinfo.sitetotalDeposit)/1e18).toFixed(4)} MATIC</h6>
              </div>
            </div>
          </div>
              </div>
          </div>
        </div>
      </div>


       <div className="deposit_tables">
         <div className="container">
          <div className="row ">
              <div className="col-lg-6"> 
              <h5>Your Deposits</h5>
              
                        <div className="table-responsive">
                          <table className="table plan_income_table">
                            <thead>
                              <tr>
                                <th>Date & Time</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                            {userdetails && userdetails.deposittime &&
                              (userdetails.deposittime).length > 0 &&
                              (userdetails.deposittime).map((item, index) => {
                              return index<10 ?(
                                <tr>
                                  <td>{moment.unix(item).format("DD-MM-YY hh:mm")}</td>
                                  <td>{(parseFloat(userdetails.depositamount[index])/1e18).toFixed(5)}</td>
                                </tr>
                              ):""
                            })}
                            </tbody>
                          </table>
                        </div> 
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0"> 
              <h5>Latest Deposits</h5>
                     
                        <div className="table-responsive">
                          <table className="table plan_income_table">
                            <thead>
                              <tr>
                                <th>Date & Time</th>
                                <th>Address</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                            {historylist && (historylist).length > 0 &&
                              (historylist).map((item, index) => {
                              return (
                                <tr>
                                  <td>{moment.unix(item.date).format("DD-MM-YY hh:mm")}</td>
                                  <td>{item.address}</td>
                                  <td>{(parseFloat(item.amount)/1e18).toFixed(5)}</td>
                                </tr>
                              )
                            })}
                            </tbody>
                          </table>
                        </div>
                      
              </div>
           </div>
          </div>
        </div>
      <Footer />
    </div>
  );
}
