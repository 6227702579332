import React, { useState, useEffect } from "react";
import $ from "jquery";
import { getHeaderdetails } from "../action/Apicontroller";
import { Scrollbars } from "react-custom-scrollbars";
import Web3 from "web3";
import polygonapi from "../ABI/polytrustABI";
import config from '../config/config'
export default function Aboveinfo(props) {
  const [details, setdetails] = useState({});
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalUser, setTotalUser] = useState(0);

  useEffect(() => {
    loadScript1();
    getdetails();
  }, []);

  function loadScript1() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".above_info_box").addClass("above_fixed");
      } else {
        $(".above_info_box").removeClass("above_fixed");
      }
    });
  }

  async function getdetails() {
    const web3 = new Web3('https://polygon-rpc.com');//mainnet
    var polygonContract = new web3.eth.Contract(
      polygonapi,
      config.polytrustcontract
    );
    let totalProfits = await polygonContract.methods.totalProfit().call();
    totalProfits = parseFloat(totalProfits) / 1e18;
    let totalUser = await polygonContract.methods.totalUsers().call();
    setTotalProfit(totalProfits)
    setTotalUser(totalUser)
  }

  // async function getdetails() {
  //   let record = await getHeaderdetails();
  //   if (record && record.data && record.data.data) {
  //     setdetails(record.data.data);
  //   }
  // }

  return (
    <section className="above_info_box">
      <div className="container">
        <Scrollbars style={{ width: "100%", height: 21 }}>
          <div className="above_box_flx">
            <div>
              <p>All participants</p>
              <span>
                {totalUser}
                {/* {details && details.total_user ? details.total_user : "--"} */}
              </span>
            </div>

            {/*<div>
              <p>Joined in 24 hours </p>
              <span>
                {details && details.users24
                  ? parseInt(details.users24) + parseInt(1)
                  : "--"}
              </span>
            </div>*/}
            <div>
              <p>Profit users result </p>
              <span>
                {totalProfit && totalProfit > 0 ? parseFloat(totalProfit).toFixed(4) : 0}
                {/* {details && details.tot_prof ? details.tot_prof : "--"} */}
              </span>
            </div>
          </div>
        </Scrollbars>
      </div>
    </section>
  );
}
