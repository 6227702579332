import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { AnimatedTree } from "react-tree-graph";
import "react-tree-graph/dist/style.css";
import Web3 from "web3";
import {
  getCurAddr,
  get_levelcounts,
  get_planAlist,
} from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import {
  Multicall,
  ContractCallResults,
  ContractCallContext,
} from "ethereum-multicall";
import syncEach from "sync-each";
import polygonapi from "../ABI/polytrustABI";
import timestampapi from "../ABI/timestampapi";
import config from "../config/config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import BigNumber from "bignumber.js";
import moment from "moment";
import Aboveinfo from "../components/aboveinfo.js";
import { setWallet } from "../reducers/Actions";
import { type WalletClient, useWalletClient } from 'wagmi'
import { BrowserProvider, JsonRpcSigner } from 'ethers'
import { useSelector, useDispatch } from "react-redux";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Dashboard(props) {
  var count = 0;

  useEffect(() => {
    window.addEventListener("resize", larg);
  }, []);

  const [userdata, setdata] = useState({});
  const [withdrawprogress, setwithdrawprogress] = useState("false");
  const [mynetwork, setmynetwork] = useState([]);
  const [level1, setlevel1] = useState([]);
  const [level2, setlevel2] = useState([]);
  const [level3, setlevel3] = useState([]);
  const [level4, setlevel4] = useState([]);
  const [selected_id, setselected_id] = useState("");
  const [treeprogress, settreeprogress] = useState(false);
  const [levelcounts, setlevelcounts] = useState("");
  const [levelload, setlevelload] = useState(true);
  const [planrange, setplanrange] = useState([]);
  const [planprogress, setplanprogress] = useState(true);
  const [gpr1count, setgpr1count] = useState(0);
  const [gpr2count, setgpr2count] = useState(0);
  const [depositLoad, setdepositLoad] = useState(false);
  const [depositamt, setdepositamt] = useState(0);

  async function larg() {
    setwidth(window.innerWidth - 50);
    setheight(window.innerHeight - 50);
  }

  const [selected, setSelected] = useState({});
  const [allselected, setallSelected] = useState({});
  const [width, setwidth] = useState(window.innerWidth - 50);
  const [height, setheight] = useState(window.innerHeight - 50);
  const [userdetails, setuserdetails] = useState({});
  const [totaluser, settotaluser] = useState(0);
  const [buyprocessing, setbuyprocessing] = useState("false");
  const [downlinefirst, setdownlinefirst] = useState([]);
  const [downlinesecond, setdownlinesecond] = useState([]);
  const [downlinethird, setdownlinethird] = useState([]);
  const [downlinefourth, setdownlinefourth] = useState([]);
  const [downlinefifth, setdownlinefifth] = useState([]);
  const [useraddress, setaddress] = useState("");
  const [owner, setowner] = useState("");
  const [upgradeprogress, setupgradeprogress] = useState("false");
  const [remainingdays, setremainingdays] = useState(0);
  const [searchid, setsearchid] = useState("");
  const [providerValue, setproviderValue] = useState({signer:{},transport:{}});
  const dispatch = useDispatch()
  let chainid = config.networkVersion ;
  const { data: walletClient } = useWalletClient({ chainid })

  useEffect(() => {
    getdetails();
  }, [walletClient]);

  async function getdetails() {
    try {
      if (count == 0) {
        count = 1;
        let datas = await getCurAddr();
        if (
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != ""
        ) {
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );
          var polygonContract = new web3.eth.Contract(
            polygonapi,
            config.polytrustcontract
          );
          let _owner = await polygonContract.methods.ownerWallet().call();
          let roi_start_time = await polygonContract.methods
            .roiStartTime(datas.address)
            .call();
          let roi_end_time = await polygonContract.methods
            .roiEndTime(datas.address)
            .call();
          let launchtime = await polygonContract.methods.roiLaunchTime().call();
          let timestampcontract = new web3.eth.Contract(timestampapi, config.timestampcontract);
          let nowtimestamp = await timestampcontract.methods.getCurrentTimestamp().call();
           //let nowtimestamp = moment().unix();
          let sec_hund = parseInt(launchtime) + 100 * 24 * 60 * 60;
          var startdate =
            roi_start_time && roi_start_time != 0 ? roi_start_time : launchtime;
          var enddate =
            roi_end_time && roi_end_time != 0 ? roi_end_time : sec_hund;
          var duration = parseInt(nowtimestamp) - parseInt(startdate);
          var remaining_days = 100 - parseInt(duration / 86400);
          remaining_days = remaining_days > 0 ? remaining_days : 0;
          setremainingdays(remaining_days);
          setowner(_owner);
          setdata(datas);
          setaddress(datas.address);
          if (datas.userdetails && datas.userdetails.isExist) {
            getuserdetails(datas.address);
            let user_data = {
              address: datas.address,
            };
            let level_counts = await get_levelcounts(user_data);
            let planAlist = await get_planAlist(user_data);
            if (planAlist && planAlist.data && planAlist.data.records) {
              setplanrange(planAlist.data.records);
              setplanprogress(false);
            } else {
              setplanrange("");
              setplanprogress(false);
            }
            if (
              level_counts &&
              level_counts.data &&
              level_counts.data.records
            ) {
              setlevelcounts(level_counts.data.records);
              setlevelload(false);
            } else {
              setlevelcounts("");
              setlevelload(false);
            }
          } else {
            window.location.href = "/join-now";
          }
        } else {
          //window.location.href = "/";
        }
      }
    } catch (err) {
      console.log(err, "======eee==e=e=");
    }
  }

  const depositamtchange = async (e) => {
    setdepositamt(e.target.value);
  };

  const deposit_busd = async () => {
    try {
        setdepositLoad(true)
        let datas = await getCurAddr();
       if (datas && datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != "") {
          if (datas.userdetails &&
            datas.userdetails.isExist) {
            if(depositamt == null || depositamt == undefined || depositamt =="" ){
                setdepositLoad(false);
                toastAlert('error', "Invalid Deposit Amount", 'network');
              }else if (parseFloat(depositamt) <= 0) {
                setdepositLoad(false);
                toastAlert('error', "Invalid Deposit Amount", 'network');
              }else{
                let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
                var polygonContract = new web3.eth.Contract(polygonapi, config.polytrustcontract);
                let price = parseFloat(depositamt) * 1e18;
                let sendamt = await convert(price);
                console.log(sendamt,"===sendamtsendamt")
                let register = await polygonContract.methods.depositToUpgrade().send({
                  from: datas.address,
                  value: sendamt.toString()
                })
                if (register && register.status && register.transactionHash) {
                  toastAlert('success', "Deposited Successfully", 'network');
                  setdepositLoad(false);
                  window.location.reload();
                } else {
                  setdepositLoad(false);
                  toastAlert('error', "Failed , try again later", 'network');
                }
              }
            } else {
              setdepositLoad(false);
              toastAlert("error", "Join First to proceed Further", "network");
              window.location.href = "/join-now";
            }
          } else {
            setdepositLoad(false);
            toastAlert("error", "Please select Matic chain network", "network");
          }
        } catch (err) {
          setdepositLoad(false);
          console.log(err, "ooooooooerererere");
        }
  };

  async function buynow(_level) {
    if (buyprocessing == "false") {
      setbuyprocessing("true");
      try {
        let datas = await getCurAddr();
        if (
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != ""
        ) {
          if (datas.userdetails && datas.userdetails.isExist) {
            if (
              parseInt(datas.userdetails.currentLevel) + parseInt(1) ==
              _level
            ) {
              let web3 = new Web3(
                datas &&
                datas.provider &&
                datas.provider != null &&
                datas.provider != undefined &&
                datas.provider != ""
                  ? datas.provider
                  : window.ethereum
              );
              var polygonContract = new web3.eth.Contract(
                polygonapi,
                config.polytrustcontract
              );
              let levelprice = await polygonContract.methods
                .LEVEL_PRICE(_level)
                .call();
              levelprice = parseFloat(levelprice);
              let sendamt = await convert(levelprice);
              if (parseFloat(datas.polygonvalue) > parseFloat(levelprice)) {
                let register = await polygonContract.methods
                  .buyLevel(_level)
                  .send({
                    from: datas.address,
                    value: sendamt.toString(),
                  });
                if (register && register.status && register.transactionHash) {
                  toastAlert("success", "Purchased Successfully", "network");
                  setbuyprocessing("false");
                  window.location.reload();
                } else {
                  setbuyprocessing("false");
                  toastAlert("error", "Failed , try again later", "network");
                }
              } else {
                setbuyprocessing("false");
                toastAlert("error", "Low Balance", "network");
              }
            } else {
              setbuyprocessing("false");
              if (parseInt(datas.userdetails.currentLevel) > _level) {
                toastAlert("error", "Incorrect Level", "network");
              } else if (parseInt(datas.userdetails.currentLevel) <= _level) {
                toastAlert("error", "Level already Purchased", "network");
              }
            }
          } else {
            setbuyprocessing("false");
            toastAlert("error", "Join First to proceed Further", "network");
            window.location.href = "/join-now";
          }
        } else {
          setbuyprocessing("false");
          toastAlert("error", "Please select Matic chain network", "network");
        }
      } catch (err) {
        setbuyprocessing("false");
        console.log(err, "======err in buy");
      }
    }
  }

  async function claimRewards() {
    setwithdrawprogress("true");
    try {
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != "" ) {
        if (datas.userdetails &&
          datas.userdetails.isExist) {
          let amtt = parseFloat(userdetails.roi_income)+parseFloat(datas.userdetails.earnedAmount)
          if (parseFloat(amtt) > 0 ) { //&& datas.userdetails.upgradeAmount <=0 ) {
            let web3 = new Web3(datas && datas.provider && datas.provider != null && datas.provider != undefined && datas.provider != "" ? datas.provider : window.ethereum);
            var polygonContract = new web3.eth.Contract(polygonapi, config.polytrustcontract);
            let register = await polygonContract.methods.claimRewards().send({
              from: datas.address
            })
            if (register && register.status && register.transactionHash) {
              toastAlert('success', "Earnings Claimed Successfully", 'network');
              setwithdrawprogress("false");
              window.location.reload();
            }else{
              toastAlert('success', "Transaction Failed", 'network');
              setwithdrawprogress("false");
              window.location.reload();
            } 
          } else {
            setwithdrawprogress("false");
            toastAlert('error', "Invalid Withdraw Amount", 'network');
          }
        } else {
          setwithdrawprogress("false");
          toastAlert("error", "Join First to proceed Further", "network");
          window.location.href = "/join-now";
        }
      } else {
        setwithdrawprogress("false");
        toastAlert("error", "Please select Matic chain network", "network");
      }
    } catch (err) {
      setwithdrawprogress("false");
      console.log(err, "======err in claim");
    }
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = config.Front_URL + "join-now/" + useraddress;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  var buy_item_carousel = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "1px",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  async function getuserdetails(addr) {
    settreeprogress(true);
    let datas = await getCurAddr();
    let web3call = new Web3(
      datas &&
      datas.provider &&
      datas.provider != null &&
      datas.provider != undefined &&
      datas.provider != ""
        ? datas.provider
        : window.ethereum
    );
    try {
      let address =
        datas && datas.address
          ? datas.address
          : localStorage.getItem("account");
      const multicall = new Multicall({
        web3Instance: web3call,
      });
      console.log(address);
      var contractCallContext = [
        {
          reference: "details",
          contractAddress: config.polytrustcontract,
          abi: polygonapi,
          calls: [
            {
              reference: "userprofile",
              methodName: "users",
              methodParameters: [address],
            },
            {
              reference: "totaluser",
              methodName: "totalUsers",
              methodParameters: [],
            },
            {
              reference: "viewallDirectUserReferral",
              methodName: "viewallDirectUserReferral",
              methodParameters: [address],
            },
            {
              reference: "viewUserReferral",
              methodName: "viewUserReferral",
              methodParameters: [address],
            },
            {
              reference: "missedearing2",
              methodName: "getmissedvalue",
              methodParameters: [address, 2],
            },
            {
              reference: "missedearing3",
              methodName: "getmissedvalue",
              methodParameters: [address, 3],
            },
            {
              reference: "missedearing4",
              methodName: "getmissedvalue",
              methodParameters: [address, 4],
            },
            {
              reference: "missedearing5",
              methodName: "getmissedvalue",
              methodParameters: [address, 5],
            },
            {
              reference: "missedearing6",
              methodName: "getmissedvalue",
              methodParameters: [address, 6],
            },
            {
              reference: "missedearing7",
              methodName: "getmissedvalue",
              methodParameters: [address, 7],
            },
            {
              reference: "missedearing8",
              methodName: "getmissedvalue",
              methodParameters: [address, 8],
            },
            {
              reference: "viewallDirectUserReferral",
              methodName: "viewallDirectUserReferral",
              methodParameters: [address],
            },
            {
              reference: "checkRoiUpto",
              methodName: "checkRoiUpto",
              methodParameters: [address],
            },

            {
              reference: "missedearing9",
              methodName: "getmissedvalue",
              methodParameters: [address, 9],
            },
            {
              reference: "missedearing10",
              methodName: "getmissedvalue",
              methodParameters: [address, 10],
            },
          ],
        },
      ];
      const results = await multicall.call(contractCallContext);
      var userprofile = await getFormatMulticall(results, "userprofile", 0);
      var total_user = await getFormatMulticall(results, "totaluser", 0);
      var directreferral = await getFormatMulticall(
        results,
        "viewallDirectUserReferral",
        0
      );
      var downlinenode = await getFormatMulticall(
        results,
        "viewUserReferral",
        0
      );
      var missedearing2 = await getFormatMulticall(results, "missedearing2", 0);
      var missedearing3 = await getFormatMulticall(results, "missedearing3", 0);
      var missedearing4 = await getFormatMulticall(results, "missedearing4", 0);
      var missedearing5 = await getFormatMulticall(results, "missedearing5", 0);
      var missedearing6 = await getFormatMulticall(results, "missedearing6", 0);
      var missedearing7 = await getFormatMulticall(results, "missedearing7", 0);
      var missedearing8 = await getFormatMulticall(results, "missedearing8", 0);
      var missedearing9 = await getFormatMulticall(results, "missedearing9", 0);
      var missedearing10 = await getFormatMulticall(results, "missedearing10", 0);
      var myreferral = await getFormatMulticall(
        results,
        "viewallDirectUserReferral",
        0
      );
      var checkRoiUpto = await getFormatMulticall(results, "checkRoiUpto", 0);
      let usercount = await bignumber(total_user[0]);
      settotaluser(usercount);
      if (userprofile[0]) {
        let userexist = true;
        let userid = await bignumber(userprofile[1]);
        let currlevel = await bignumber(userprofile[3]);
        let earned_Amount = await bignumber(userprofile[4]);
        let total_earned_Amount = await bignumber(userprofile[5]);
        let childcount = await bignumber(userprofile[6]);
        let upgradeamt = await bignumber(userprofile[7]);
        let upgradePending = await bignumber(userprofile[8]);
              console.log(upgradePending,"=====useusuueue")

        let l2_missedearnings = await bignumber(missedearing2[0]);
        let l3_missedearnings = await bignumber(missedearing3[0]);
        let l4_missedearnings = await bignumber(missedearing4[0]);
        let l5_missedearnings = await bignumber(missedearing5[0]);
        let l6_missedearnings = await bignumber(missedearing6[0]);
        let l7_missedearnings = await bignumber(missedearing7[0]);
        let l8_missedearnings = await bignumber(missedearing8[0]);
        let l9_missedearnings = await bignumber(missedearing9[0]);
        let l10_missedearnings = await bignumber(missedearing10[0]);
        let roi_income = await bignumber(checkRoiUpto[0]);
        let data_user = {
          userexist: userexist,
          userid: userid,
          earnedAmount: earned_Amount,
          totalearnedAmount: total_earned_Amount,
          currlevel: parseInt(currlevel),
          directreferral: directreferral,
          downlinenode: downlinenode,
          childcount: childcount,
          upgradeamt: upgradeamt,
          upgradePending: upgradePending,
          l2_missedearnings: l2_missedearnings,
          l3_missedearnings: l3_missedearnings,
          l4_missedearnings: l4_missedearnings,
          l5_missedearnings: l5_missedearnings,
          l6_missedearnings: l6_missedearnings,
          l7_missedearnings: l7_missedearnings,
          l8_missedearnings: l8_missedearnings,
          l9_missedearnings: l9_missedearnings,
          l10_missedearnings: l10_missedearnings,
          roi_income: roi_income,
        };
        console.log(data_user,"===l9_missedearnings",l10_missedearnings)
        setuserdetails(data_user);
        let _my_ref = [];
        if (myreferral.length > 0) {
          for (let noderef in myreferral) {
            var _myref = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [myreferral[noderef]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [myreferral[noderef]],
                  },
                ],
              },
            ];
            const _results = await multicall.call(_myref);
            var _userprofile = await getFormatMulticall(
              _results,
              "userprofile",
              0
            );
            var _downlinenode = await getFormatMulticall(
              _results,
              "viewUserReferral",
              0
            );
            let _userid = await bignumber(_userprofile[1]);
            let _currlevel = await bignumber(_userprofile[3]);
            let _downline = {
              address: myreferral[noderef],
              level: _currlevel,
              userid: _userid,
              downlinenode: _downlinenode,
            };
            _my_ref.push(_downline);
            if (parseFloat(noderef) + 1 == myreferral.length) {
              setmynetwork(_my_ref);
            }
          }
        }

        //******downlinecalculation  1************//
        let downline1 = [];
        let downline2arr = [];
        let downline3arr = [];
        let downline4arr = [];
        let downline5arr = [];
        let downline2 = [];
        let downline3 = [];
        let downline4 = [];
        let downline5 = [];
        let downline6arr = [];
        if (downlinenode.length > 0) {
          for (let node in downlinenode) {
            var downlineCallContext = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downlinenode[node]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downlinenode[node]],
                  },
                ],
              },
            ];
            const results1 = await multicall.call(downlineCallContext);
            var userprofile1 = await getFormatMulticall(
              results1,
              "userprofile",
              0
            );
            var downlinenode1 = await getFormatMulticall(
              results1,
              "viewUserReferral",
              0
            );
            let userid1 = await bignumber(userprofile1[1]);
            let currlevel1 = await bignumber(userprofile1[3]);
            let downline_1 = {
              address: downlinenode[node],
              level: currlevel1,
              userid: userid1,
              downlinenode: downlinenode1,
            };
            downline1.push(downline_1);
            downline2arr = downline2arr.concat(downlinenode1);
            if (parseFloat(node) + 1 == downlinenode.length) {
              setdownlinefirst(downline1);
            }
          }
        }
        //*******downline2*******//
        if (downline2arr.length > 0) {
          for (let node1 in downline2arr) {
            var downlineCallContext0 = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline2arr[node1]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline2arr[node1]],
                  },
                ],
              },
            ];
            const results2 = await multicall.call(downlineCallContext0);
            var userprofile2 = await getFormatMulticall(
              results2,
              "userprofile",
              0
            );
            var downlinenode2 = await getFormatMulticall(
              results2,
              "viewUserReferral",
              0
            );
            let userid2 = await bignumber(userprofile2[1]);
            let currlevel2 = await bignumber(userprofile2[3]);
            let downline_2 = {
              address: downline2arr[node1],
              level: currlevel2,
              userid: userid2,
              downlinenode: downlinenode2,
            };
            downline2.push(downline_2);
            downline3arr = downline3arr.concat(downlinenode2);
            if (parseFloat(node1) + 1 == downline2arr.length) {
              setdownlinesecond(downline2);
            }
          }
        }

        //************downline3******************************//
        if (downline3arr.length > 0) {
          for (let node2 in downline3arr) {
            var downlineCallContext1 = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline3arr[node2]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline3arr[node2]],
                  },
                ],
              },
            ];
            const results3 = await multicall.call(downlineCallContext1);
            var userprofile3 = await getFormatMulticall(
              results3,
              "userprofile",
              0
            );
            var downlinenode3 = await getFormatMulticall(
              results3,
              "viewUserReferral",
              0
            );
            let userid3 = await bignumber(userprofile3[1]);
            let currlevel3 = await bignumber(userprofile3[3]);
            let downline_3 = {
              address: downline3arr[node2],
              level: currlevel3,
              userid: userid3,
              downlinenode: downlinenode3,
            };
            downline3.push(downline_3);
            downline4arr = downline4arr.concat(downlinenode3);
            if (parseFloat(node2) + 1 == downline3arr.length) {
              setdownlinethird(downline3);
            }
          }
        }

        //************downline4******************************//
        if (downline4arr.length > 0) {
          for (let node3 in downline4arr) {
            var downlineCallContext3 = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline4arr[node3]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline4arr[node3]],
                  },
                ],
              },
            ];
            const results4 = await multicall.call(downlineCallContext3);
            var userprofile4 = await getFormatMulticall(
              results4,
              "userprofile",
              0
            );
            var downlinenode4 = await getFormatMulticall(
              results4,
              "viewUserReferral",
              0
            );
            let userid4 = await bignumber(userprofile4[1]);
            let currlevel4 = await bignumber(userprofile4[3]);
            let downline_4 = {
              address: downline4arr[node3],
              level: currlevel4,
              userid: userid4,
              downlinenode: downlinenode4,
            };
            downline4.push(downline_4);
            downline5arr = downline5arr.concat(downlinenode4);
            if (parseFloat(node3) + 1 == downline4arr.length) {
              setdownlinefourth(downline4);
            }
          }
        }

         //************downline5******************************//
      if (downline5arr.length > 0) {
          for (let node4 in downline5arr) {
            var downlineCallContext4 = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline5arr[node4]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline5arr[node4]],
                  },
                ],
              },
            ];
            const results5 = await multicall.call(downlineCallContext4);
            var userprofile5 = await getFormatMulticall(
              results5,
              "userprofile",
              0
            );
            var downlinenode5 = await getFormatMulticall(
              results5,
              "viewUserReferral",
              0
            );
            let userid5 = await bignumber(userprofile5[1]);
            let currlevel5 = await bignumber(userprofile5[3]);
            let downline_5 = {
              address: downline5arr[node4],
              level: currlevel5,
              userid: userid5,
              downlinenode: downlinenode5,
            };
            downline5.push(downline_5);
            downline6arr = downline6arr.concat(downlinenode5);
            if (parseFloat(node4) + 1 == downline5arr.length) {
              setdownlinefifth(downline5);
            }
          }
        }

        let line1arr = [];
        if (downline1.length > 0) {
          syncEach(
            downline1,
            async function (items, next) {
              if (
                items &&
                items.downlinenode &&
                items.downlinenode.length > 0
              ) {
                let subline = [];
                syncEach(
                  items.downlinenode,
                  async function (subitems, next1) {
                    let IndexVal = downline2.findIndex(
                      (val) => val.address == subitems
                    );
                    if (
                      IndexVal >= 0 &&
                      downline2[IndexVal].downlinenode &&
                      downline2[IndexVal].downlinenode.length > 0
                    ) {
                      let subline2 = [];
                      syncEach(
                        downline2[IndexVal].downlinenode,
                        async function (thir_items, next2) {
                          let IndexVal1 = downline3.findIndex(
                            (val) => val.address == thir_items
                          );
                          if (
                            IndexVal1 >= 0 &&
                            downline3[IndexVal1].downlinenode &&
                            downline3[IndexVal1].downlinenode.length > 0
                          ) {
                            let subline3 = [];
                            syncEach(
                              downline3[IndexVal1].downlinenode,
                              async function (fourth_items, next3) {
                                let IndexvVal = downline4.findIndex(
                                  (val) => val.address == fourth_items
                                );
                              if (
                                IndexvVal >= 0 &&
                                downline4[IndexvVal].downlinenode &&
                                downline4[IndexvVal].downlinenode.length > 0
                              ) {
                                let subline4 = [];
                                 syncEach(
                                  downline4[IndexvVal].downlinenode,
                                  async function (fifth_items, next4) { 
                                    let IndexxVal = downline5.findIndex(
                                      (val) => val.address == fifth_items
                                    );
                                      let user22 = {
                                        name:
                                          "ID " +
                                          downline5[IndexxVal].userid +
                                          " (" +
                                          downline5[IndexxVal].level +
                                          ")",
                                      };
                                      subline4.push(user22);
                                      next4();
                                    },
                                    function (error, success) {
                                      let mainline = {
                                        name:
                                          "ID " +
                                          downline4[IndexvVal].userid +
                                          " (" +
                                          downline4[IndexvVal].level +
                                          ")",
                                        children: subline4,
                                      };
                                      setlevel4(mainline);
                                      subline3.push(mainline);
                                      next3();
                                    }
                                  );


                              }else{
                                let Index_Val = downline4.findIndex(
                                  (val) => val.address == fourth_items
                                );
                                let user22 = {
                                  name:
                                    "ID " +
                                    downline4[Index_Val].userid +
                                    " (" +
                                    downline4[Index_Val].level +
                                    ")",
                                };
                                subline3.push(user22);
                                next3();
                              }

                              },
                              function (error, success) {
                                let mainline = {
                                  name:
                                    "ID " +
                                    downline3[IndexVal1].userid +
                                    " (" +
                                    downline3[IndexVal1].level +
                                    ")",
                                  children: subline3,
                                };
                                setlevel3(mainline);
                                subline2.push(mainline);
                                next2();
                              }
                            );
                          } else {
                            let IndexVal = downline3.findIndex(
                              (val) => val.address == thir_items
                            );
                            let user1 = {
                              name:
                                "ID " +
                                downline3[IndexVal].userid +
                                " (" +
                                downline3[IndexVal].level +
                                ")",
                            };
                            subline2.push(user1);
                            next2();
                          }
                          // let user12 = {
                          //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                          // }
                          // subline2.push(user12);
                          // next2();
                        },
                        function (error, success) {
                          let mainline = {
                            name:
                              "ID " +
                              downline2[IndexVal].userid +
                              " (" +
                              downline2[IndexVal].level +
                              ")",
                            children: subline2,
                          };
                          setlevel2(mainline);
                          subline.push(mainline);
                          next1();
                        }
                      );
                    } else {
                      var IndexValue = downline2.findIndex(
                        (val) => val.address == subitems
                      );
                      let user1 = {
                        name:
                          "ID " +
                          downline2[IndexValue].userid +
                          " (" +
                          downline2[IndexValue].level +
                          ")",
                      };
                      subline.push(user1);
                      next1();
                    }
                  },
                  function (errs, t_Items) {
                    let mainline = {
                      name: "ID " + items.userid + " (" + items.level + ")",
                      children: subline,
                    };
                    setlevel1(mainline);
                    line1arr.push(mainline);
                    next();
                  }
                );
              } else {
                let mainline = {
                  name: "ID " + items.userid + " (" + items.level + ")",
                  children: [],
                };
                setlevel1(mainline);
                setlevel2([]);
                line1arr.push(mainline);
                next();
              }
            },
            function (err, transformedItems) {
              let mainlinearr = {
                name: "ID " + userid + " (" + currlevel + ")",
                children: line1arr,
              };
              setSelected(mainlinearr);
              setallSelected(mainlinearr);
              settreeprogress(false);
              let my_network = downline1.concat(downline2);
            }
          );
        } else {
          let mainlinearr = {
            name: "ID " + userid + " (" + currlevel + ")",
            children: [],
          };
          setSelected(mainlinearr);
          setallSelected(mainlinearr);
          settreeprogress(false);
          let my_network = downline1.concat(downline2);
          setmynetwork(my_network);
        }
      }
    } catch (err) {
      console.log(err, "=====essrrr");
    }
  }

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  function getFormatMulticall(results, name, pos) {
    try {
      var index =
        results &&
        results.results &&
        results.results.details &&
        results.results.details.callsReturnContext &&
        results.results.details.callsReturnContext.findIndex(
          (val) => val.reference == name
        );
      var returnVal =
        results.results.details.callsReturnContext[index] &&
        results.results.details.callsReturnContext[index].returnValues
          ? results.results.details.callsReturnContext[index].returnValues
          : "";
      return returnVal;
    } catch (err) {
      console.log(err, "==+++++++");
      return "";
    }
  }

  async function searchbyid() {
    try {
      if (
        searchid &&
        searchid != null &&
        searchid != undefined &&
        searchid != ""
      ) {
        let userid = searchid;
        if (selected_id == userid) {
          setSelected(allselected);
          setselected_id("");
        } else {
          settreeprogress(true);
          let datas = await getCurAddr();
          let web3 = new Web3(
            datas &&
            datas.provider &&
            datas.provider != null &&
            datas.provider != undefined &&
            datas.provider != ""
              ? datas.provider
              : window.ethereum
          );
          const multicall = new Multicall({
            web3Instance: web3,
          });
          var polygonContract = new web3.eth.Contract(
            polygonapi,
            config.polytrustcontract
          );
          let c_address = await polygonContract.methods.userList(userid).call();
          if (
            c_address &&
            c_address != null &&
            c_address != undefined &&
            c_address != "" &&
            c_address != "0x0000000000000000000000000000000000000000"
          ) {
            setselected_id(userid);
            let downlinenode = await polygonContract.methods
              .viewUserReferral(c_address)
              .call();
            let leveldetails = await polygonContract.methods
              .users(c_address)
              .call();
            let currlevel = leveldetails && leveldetails.currentLevel;
            let downline1 = [];
            let downline2arr = [];
            let downline3arr = [];
            let downline4arr = [];
            let downline5arr = [];
            let downline6arr = [];
            let downline2 = [];
            let downline3 = [];
            let downline4 = [];
            let downline5 = [];
            if (downlinenode.length > 0) {
              for (let node in downlinenode) {
                var downlineCallContext = [
                  {
                    reference: "details",
                    contractAddress: config.polytrustcontract,
                    abi: polygonapi,
                    calls: [
                      {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downlinenode[node]],
                      },
                      {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downlinenode[node]],
                      },
                    ],
                  },
                ];
                const results1 = await multicall.call(downlineCallContext);
                var userprofile1 = await getFormatMulticall(
                  results1,
                  "userprofile",
                  0
                );
                var downlinenode1 = await getFormatMulticall(
                  results1,
                  "viewUserReferral",
                  0
                );
                let userid1 = await bignumber(userprofile1[1]);
                let currlevel1 = await bignumber(userprofile1[3]);
                let downline_1 = {
                  address: downlinenode[node],
                  level: currlevel1,
                  userid: userid1,
                  downlinenode: downlinenode1,
                };
                downline1.push(downline_1);
                downline2arr = downline2arr.concat(downlinenode1);
                if (parseFloat(node) + 1 == downlinenode.length) {
                  setdownlinefirst(downline1);
                }
              }
            }
            //*******downline2*******//
            if (downline2arr.length > 0) {
              for (let node1 in downline2arr) {
                var downlineCallContext0 = [
                  {
                    reference: "details",
                    contractAddress: config.polytrustcontract,
                    abi: polygonapi,
                    calls: [
                      {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline2arr[node1]],
                      },
                      {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downline2arr[node1]],
                      },
                    ],
                  },
                ];
                const results2 = await multicall.call(downlineCallContext0);
                var userprofile2 = await getFormatMulticall(
                  results2,
                  "userprofile",
                  0
                );
                var downlinenode2 = await getFormatMulticall(
                  results2,
                  "viewUserReferral",
                  0
                );
                let userid2 = await bignumber(userprofile2[1]);
                let currlevel2 = await bignumber(userprofile2[3]);
                let downline_2 = {
                  address: downline2arr[node1],
                  level: currlevel2,
                  userid: userid2,
                  downlinenode: downlinenode2,
                };
                downline2.push(downline_2);
                downline3arr = downline3arr.concat(downlinenode2);
                if (parseFloat(node1) + 1 == downline2arr.length) {
                  setdownlinesecond(downline2);
                }
              }
            }

            //************downline3******************************//
            if (downline3arr.length > 0) {
              for (let node2 in downline3arr) {
                var downlineCallContext1 = [
                  {
                    reference: "details",
                    contractAddress: config.polytrustcontract,
                    abi: polygonapi,
                    calls: [
                      {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline3arr[node2]],
                      },
                      {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downline3arr[node2]],
                      },
                    ],
                  },
                ];
                const results3 = await multicall.call(downlineCallContext1);
                var userprofile3 = await getFormatMulticall(
                  results3,
                  "userprofile",
                  0
                );
                var downlinenode3 = await getFormatMulticall(
                  results3,
                  "viewUserReferral",
                  0
                );
                let userid3 = await bignumber(userprofile3[1]);
                let currlevel3 = await bignumber(userprofile3[3]);
                let downline_3 = {
                  address: downline3arr[node2],
                  level: currlevel3,
                  userid: userid3,
                  downlinenode: downlinenode3,
                };
                downline3.push(downline_3);
                downline4arr = downline4arr.concat(downlinenode3);
                if (parseFloat(node2) + 1 == downline3arr.length) {
                  setdownlinethird(downline3);
                }
              }
            }

            //************downline4******************************//
            if (downline4arr.length > 0) {
              for (let node3 in downline4arr) {
                var downlineCallContext3 = [
                  {
                    reference: "details",
                    contractAddress: config.polytrustcontract,
                    abi: polygonapi,
                    calls: [
                      {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline4arr[node3]],
                      },
                      {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downline4arr[node3]],
                      },
                    ],
                  },
                ];
                const results4 = await multicall.call(downlineCallContext3);
                var userprofile4 = await getFormatMulticall(
                  results4,
                  "userprofile",
                  0
                );
                var downlinenode4 = await getFormatMulticall(
                  results4,
                  "viewUserReferral",
                  0
                );
                let userid4 = await bignumber(userprofile4[1]);
                let currlevel4 = await bignumber(userprofile4[3]);
                let downline_4 = {
                  address: downline4arr[node3],
                  level: currlevel4,
                  userid: userid4,
                  downlinenode: downlinenode4,
                };
                downline4.push(downline_4);
                downline5arr = downline5arr.concat(downlinenode4);
                if (parseFloat(node3) + 1 == downline4arr.length) {
                  setdownlinefourth(downline4);
                }
              }
            }

               //************downline5******************************//
           if (downline5arr.length > 0) {
              for (let node4 in downline5arr) {
                var downlineCallContext4 = [
                  {
                    reference: "details",
                    contractAddress: config.polytrustcontract,
                    abi: polygonapi,
                    calls: [
                      {
                        reference: "userprofile",
                        methodName: "users",
                        methodParameters: [downline5arr[node4]],
                      },
                      {
                        reference: "viewUserReferral",
                        methodName: "viewUserReferral",
                        methodParameters: [downline5arr[node4]],
                      },
                    ],
                  },
                ];
                const results5 = await multicall.call(downlineCallContext4);
                var userprofile5 = await getFormatMulticall(
                  results5,
                  "userprofile",
                  0
                );
                var downlinenode5 = await getFormatMulticall(
                  results5,
                  "viewUserReferral",
                  0
                );
                let userid5 = await bignumber(userprofile5[1]);
                let currlevel5 = await bignumber(userprofile5[3]);
                let downline_5 = {
                  address: downline5arr[node4],
                  level: currlevel5,
                  userid: userid5,
                  downlinenode: downlinenode5,
                };
                downline5.push(downline_5);
                downline6arr = downline6arr.concat(downlinenode5);
                if (parseFloat(node4) + 1 == downline5arr.length) {
                  setdownlinefifth(downline5);
                }
              }
            }

            let line1arr = [];
            if (downline1.length > 0) {
              syncEach(
                downline1,
                async function (items, next) {
                  if (
                    items &&
                    items.downlinenode &&
                    items.downlinenode.length > 0
                  ) {
                    let subline = [];
                    syncEach(
                      items.downlinenode,
                      async function (subitems, next1) {
                        let IndexVal = downline2.findIndex(
                          (val) => val.address == subitems
                        );
                        if (
                          IndexVal >= 0 &&
                          downline2[IndexVal].downlinenode &&
                          downline2[IndexVal].downlinenode.length > 0
                        ) {
                          let subline2 = [];
                          syncEach(
                            downline2[IndexVal].downlinenode,
                            async function (thir_items, next2) {
                              let IndexVal1 = downline3.findIndex(
                                (val) => val.address == thir_items
                              );
                              if (
                                IndexVal1 >= 0 &&
                                downline3[IndexVal1].downlinenode &&
                                downline3[IndexVal1].downlinenode.length > 0
                              ) {
                                 let subline3 = [];
                            syncEach(
                              downline3[IndexVal1].downlinenode,
                              async function (fourth_items, next3) {
                                let IndexvVal = downline4.findIndex(
                                  (val) => val.address == fourth_items
                                );
                              if (
                                IndexvVal >= 0 &&
                                downline4[IndexvVal].downlinenode &&
                                downline4[IndexvVal].downlinenode.length > 0
                              ) {
                                let subline4 = [];
                                 syncEach(
                                  downline4[IndexvVal].downlinenode,
                                  async function (fifth_items, next4) { 
                                    let IndexxVal = downline5.findIndex(
                                      (val) => val.address == fifth_items
                                    );
                                      let user22 = {
                                        name:
                                          "ID " +
                                          downline5[IndexxVal].userid +
                                          " (" +
                                          downline5[IndexxVal].level +
                                          ")",
                                      };
                                      subline4.push(user22);
                                      next4();
                                    },
                                    function (error, success) {
                                      let mainline = {
                                        name:
                                          "ID " +
                                          downline4[IndexvVal].userid +
                                          " (" +
                                          downline4[IndexvVal].level +
                                          ")",
                                        children: subline4,
                                      };
                                      setlevel4(mainline);
                                      subline3.push(mainline);
                                      next3();
                                    }
                                  );


                              }else{
                                let Index_Val = downline4.findIndex(
                                  (val) => val.address == fourth_items
                                );
                                let user22 = {
                                  name:
                                    "ID " +
                                    downline4[Index_Val].userid +
                                    " (" +
                                    downline4[Index_Val].level +
                                    ")",
                                };
                                subline3.push(user22);
                                next3();
                              }

                              },
                              function (error, success) {
                                let mainline = {
                                  name:
                                    "ID " +
                                    downline3[IndexVal1].userid +
                                    " (" +
                                    downline3[IndexVal1].level +
                                    ")",
                                  children: subline3,
                                };
                                setlevel3(mainline);
                                subline2.push(mainline);
                                next2();
                              }
                            );
                              } else {
                                let IndexVal = downline3.findIndex(
                                  (val) => val.address == thir_items
                                );
                                let user1 = {
                                  name:
                                    "ID " +
                                    downline3[IndexVal].userid +
                                    " (" +
                                    downline3[IndexVal].level +
                                    ")",
                                };
                                subline2.push(user1);
                                next2();
                              }
                              // let user12 = {
                              //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                              // }
                              // subline2.push(user12);
                              // next2();
                            },
                            function (error, success) {
                              let mainline = {
                                name:
                                  "ID " +
                                  downline2[IndexVal].userid +
                                  " (" +
                                  downline2[IndexVal].level +
                                  ")",
                                children: subline2,
                              };
                              setlevel2(mainline);
                              subline.push(mainline);
                              next1();
                            }
                          );
                        } else {
                          var IndexValue = downline2.findIndex(
                            (val) => val.address == subitems
                          );
                          let user1 = {
                            name:
                              "ID " +
                              downline2[IndexValue].userid +
                              " (" +
                              downline2[IndexValue].level +
                              ")",
                          };
                          subline.push(user1);
                          next1();
                        }
                      },
                      function (errs, t_Items) {
                        let mainline = {
                          name: "ID " + items.userid + " (" + items.level + ")",
                          children: subline,
                        };
                        setlevel1(mainline);
                        console.log("push33");
                        line1arr.push(mainline);
                        next();
                      }
                    );
                  } else {
                    let mainline = {
                      name: "ID " + items.userid + " (" + items.level + ")",
                      children: [],
                    };
                    setlevel1(mainline);
                    setlevel2([]);
                    line1arr.push(mainline);
                    next();
                  }
                },
                function (err, transformedItems) {
                  let mainlinearr = {
                    name: "ID " + userid + " (" + currlevel + ")",
                    children: line1arr,
                  };
                  setSelected(mainlinearr);
                  settreeprogress(false);
                }
              );
            } else {
              let mainlinearr = {
                name: "ID " + userid + " (" + currlevel + ")",
                children: [],
              };
              setSelected(mainlinearr);
              settreeprogress(false);
            }
          } else {
            settreeprogress(false);
            toastAlert("error", "Enter Valid Id", "network");
          }
        }
      } else {
        settreeprogress(false);
        toastAlert("error", "Enter Valid Id", "network");
      }
    } catch (err) {
      settreeprogress(false);
      toastAlert("error", "Enter Valid Id", "network");
    }
  }

  async function handleClick(event, node) {
    if (node && node != null && node != undefined && node != "") {
      let myArray = node.split(" ");
      let userid = myArray[1];
      if (selected_id == userid) {
        setSelected(allselected);
        setselected_id("");
      } else {
        settreeprogress(true);
        setselected_id(userid);
        let datas = await getCurAddr();
        let web3 = new Web3(
          datas &&
          datas.provider &&
          datas.provider != null &&
          datas.provider != undefined &&
          datas.provider != ""
            ? datas.provider
            : window.ethereum
        );
        const multicall = new Multicall({
          web3Instance: web3,
        });
        var polygonContract = new web3.eth.Contract(
          polygonapi,
          config.polytrustcontract
        );
        let c_address = await polygonContract.methods.userList(userid).call();
        let downlinenode = await polygonContract.methods
          .viewUserReferral(c_address)
          .call();
        let leveldetails = await polygonContract.methods
          .users(c_address)
          .call();
        let currlevel = leveldetails && leveldetails.currentLevel;
        let downline1 = [];
        let downline2arr = [];
        let downline3arr = [];
        let downline4arr = [];
        let downline5arr = [];
        let downline6arr = [];
        let downline2 = [];
        let downline3 = [];
        let downline4 = [];
        let downline5 = [];
        if (downlinenode.length > 0) {
          for (let node in downlinenode) {
            var downlineCallContext = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downlinenode[node]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downlinenode[node]],
                  },
                ],
              },
            ];
            const results1 = await multicall.call(downlineCallContext);
            var userprofile1 = await getFormatMulticall(
              results1,
              "userprofile",
              0
            );
            var downlinenode1 = await getFormatMulticall(
              results1,
              "viewUserReferral",
              0
            );
            let userid1 = await bignumber(userprofile1[1]);
            let currlevel1 = await bignumber(userprofile1[3]);
            let downline_1 = {
              address: downlinenode[node],
              level: currlevel1,
              userid: userid1,
              downlinenode: downlinenode1,
            };
            downline1.push(downline_1);
            downline2arr = downline2arr.concat(downlinenode1);
            if (parseFloat(node) + 1 == downlinenode.length) {
              setdownlinefirst(downline1);
            }
          }
        }
        //********downline2*******//
        if (downline2arr.length > 0) {
          for (let node1 in downline2arr) {
            var downlineCallContext0 = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline2arr[node1]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline2arr[node1]],
                  },
                ],
              },
            ];
            const results2 = await multicall.call(downlineCallContext0);
            var userprofile2 = await getFormatMulticall(
              results2,
              "userprofile",
              0
            );
            var downlinenode2 = await getFormatMulticall(
              results2,
              "viewUserReferral",
              0
            );
            let userid2 = await bignumber(userprofile2[1]);
            let currlevel2 = await bignumber(userprofile2[3]);
            let downline_2 = {
              address: downline2arr[node1],
              level: currlevel2,
              userid: userid2,
              downlinenode: downlinenode2,
            };
            downline2.push(downline_2);
            downline3arr = downline3arr.concat(downlinenode2);
            if (parseFloat(node1) + 1 == downline2arr.length) {
              setdownlinesecond(downline2);
            }
          }
        }

        //************downline3******************************//
        if (downline3arr.length > 0) {
          for (let node2 in downline3arr) {
            var downlineCallContext1 = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline3arr[node2]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline3arr[node2]],
                  },
                ],
              },
            ];
            const results3 = await multicall.call(downlineCallContext1);
            var userprofile3 = await getFormatMulticall(
              results3,
              "userprofile",
              0
            );
            var downlinenode3 = await getFormatMulticall(
              results3,
              "viewUserReferral",
              0
            );
            let userid3 = await bignumber(userprofile3[1]);
            let currlevel3 = await bignumber(userprofile3[3]);
            let downline_3 = {
              address: downline3arr[node2],
              level: currlevel3,
              userid: userid3,
              downlinenode: downlinenode3,
            };
            downline3.push(downline_3);
            downline4arr = downline4arr.concat(downlinenode3);
            if (parseFloat(node2) + 1 == downline3arr.length) {
              setdownlinethird(downline3);
            }
          }
        }

        //************downline4******************************//
        if (downline4arr.length > 0) {
          for (let node3 in downline4arr) {
            var downlineCallContext3 = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline4arr[node3]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline4arr[node3]],
                  },
                ],
              },
            ];
            const results4 = await multicall.call(downlineCallContext3);
            var userprofile4 = await getFormatMulticall(
              results4,
              "userprofile",
              0
            );
            var downlinenode4 = await getFormatMulticall(
              results4,
              "viewUserReferral",
              0
            );
            let userid4 = await bignumber(userprofile4[1]);
            let currlevel4 = await bignumber(userprofile4[3]);
            let downline_4 = {
              address: downline4arr[node3],
              level: currlevel4,
              userid: userid4,
              downlinenode: downlinenode4,
            };
            downline4.push(downline_4);
            downline5arr = downline5arr.concat(downlinenode4);
            if (parseFloat(node3) + 1 == downline4arr.length) {
              setdownlinefourth(downline4);
            }
          }
        }

         //************downline5******************************//
      if (downline5arr.length > 0) {
          for (let node4 in downline5arr) {
            var downlineCallContext4 = [
              {
                reference: "details",
                contractAddress: config.polytrustcontract,
                abi: polygonapi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline5arr[node4]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline5arr[node4]],
                  },
                ],
              },
            ];
            const results5 = await multicall.call(downlineCallContext4);
            var userprofile5 = await getFormatMulticall(
              results5,
              "userprofile",
              0
            );
            var downlinenode5 = await getFormatMulticall(
              results5,
              "viewUserReferral",
              0
            );
            let userid5 = await bignumber(userprofile5[1]);
            let currlevel5 = await bignumber(userprofile5[3]);
            let downline_5 = {
              address: downline5arr[node4],
              level: currlevel5,
              userid: userid5,
              downlinenode: downlinenode5,
            };
            downline5.push(downline_5);
            downline6arr = downline6arr.concat(downlinenode5);
            if (parseFloat(node4) + 1 == downline5arr.length) {
              setdownlinefifth(downline5);
            }
          }
        }

        let line1arr = [];
        if (downline1.length > 0) {
          syncEach(
            downline1,
            async function (items, next) {
              if (
                items &&
                items.downlinenode &&
                items.downlinenode.length > 0
              ) {
                let subline = [];
                syncEach(
                  items.downlinenode,
                  async function (subitems, next1) {
                    let IndexVal = downline2.findIndex(
                      (val) => val.address == subitems
                    );
                    if (
                      IndexVal >= 0 &&
                      downline2[IndexVal].downlinenode &&
                      downline2[IndexVal].downlinenode.length > 0
                    ) {
                      let subline2 = [];
                      syncEach(
                        downline2[IndexVal].downlinenode,
                        async function (thir_items, next2) {
                          let IndexVal1 = downline3.findIndex(
                            (val) => val.address == thir_items
                          );
                          if (
                            IndexVal1 >= 0 &&
                            downline3[IndexVal1].downlinenode &&
                            downline3[IndexVal1].downlinenode.length > 0
                          ) {
                             let subline3 = [];
                            syncEach(
                              downline3[IndexVal1].downlinenode,
                              async function (fourth_items, next3) {
                                let IndexvVal = downline4.findIndex(
                                  (val) => val.address == fourth_items
                                );
                              if (
                                IndexvVal >= 0 &&
                                downline4[IndexvVal].downlinenode &&
                                downline4[IndexvVal].downlinenode.length > 0
                              ) {
                                let subline4 = [];
                                 syncEach(
                                  downline4[IndexvVal].downlinenode,
                                  async function (fifth_items, next4) { 
                                    let IndexxVal = downline5.findIndex(
                                      (val) => val.address == fifth_items
                                    );
                                      let user22 = {
                                        name:
                                          "ID " +
                                          downline5[IndexxVal].userid +
                                          " (" +
                                          downline5[IndexxVal].level +
                                          ")",
                                      };
                                      subline4.push(user22);
                                      next4();
                                    },
                                    function (error, success) {
                                      let mainline = {
                                        name:
                                          "ID " +
                                          downline4[IndexvVal].userid +
                                          " (" +
                                          downline4[IndexvVal].level +
                                          ")",
                                        children: subline4,
                                      };
                                      setlevel4(mainline);
                                      subline3.push(mainline);
                                      next3();
                                    }
                                  );


                              }else{
                                let Index_Val = downline4.findIndex(
                                  (val) => val.address == fourth_items
                                );
                                let user22 = {
                                  name:
                                    "ID " +
                                    downline4[Index_Val].userid +
                                    " (" +
                                    downline4[Index_Val].level +
                                    ")",
                                };
                                subline3.push(user22);
                                next3();
                              }

                              },
                              function (error, success) {
                                let mainline = {
                                  name:
                                    "ID " +
                                    downline3[IndexVal1].userid +
                                    " (" +
                                    downline3[IndexVal1].level +
                                    ")",
                                  children: subline3,
                                };
                                setlevel3(mainline);
                                subline2.push(mainline);
                                next2();
                              }
                            );
                          } else {
                            let IndexVal = downline3.findIndex(
                              (val) => val.address == thir_items
                            );
                            let user1 = {
                              name:
                                "ID " +
                                downline3[IndexVal].userid +
                                " (" +
                                downline3[IndexVal].level +
                                ")",
                            };
                            subline2.push(user1);
                            next2();
                          }
                          // let user12 = {
                          //     name : "ID "+downline3[IndexVal].userid+" ("+downline3[IndexVal].level+")"
                          // }
                          // subline2.push(user12);
                          // next2();
                        },
                        function (error, success) {
                          let mainline = {
                            name:
                              "ID " +
                              downline2[IndexVal].userid +
                              " (" +
                              downline2[IndexVal].level +
                              ")",
                            children: subline2,
                          };
                          setlevel2(mainline);
                          subline.push(mainline);
                          next1();
                        }
                      );
                    } else {
                      var IndexValue = downline2.findIndex(
                        (val) => val.address == subitems
                      );
                      let user1 = {
                        name:
                          "ID " +
                          downline2[IndexValue].userid +
                          " (" +
                          downline2[IndexValue].level +
                          ")",
                      };
                      subline.push(user1);
                      next1();
                    }
                  },
                  function (errs, t_Items) {
                    let mainline = {
                      name: "ID " + items.userid + " (" + items.level + ")",
                      children: subline,
                    };
                    setlevel1(mainline);
                    console.log("push33");
                    line1arr.push(mainline);
                    next();
                  }
                );
              } else {
                let mainline = {
                  name: "ID " + items.userid + " (" + items.level + ")",
                  children: [],
                };
                setlevel1(mainline);
                setlevel2([]);
                line1arr.push(mainline);
                next();
              }
            },
            function (err, transformedItems) {
              let mainlinearr = {
                name: "ID " + userid + " (" + currlevel + ")",
                children: line1arr,
              };
              setSelected(mainlinearr);
              settreeprogress(false);
            }
          );
        } else {
          let mainlinearr = {
            name: "ID " + userid + " (" + currlevel + ")",
            children: [],
          };
          setSelected(mainlinearr);
          settreeprogress(false);
        }
      }
    }
  }

  async function upgradenext() {
    setupgradeprogress("true");
    try {
      let datas = await getCurAddr();
      if (
        datas &&
        datas.address &&
        datas.address != undefined &&
        datas.address != null &&
        datas.address != ""
      ) {
        if (datas.userdetails && datas.userdetails.isExist) {
          if (parseFloat(datas.userdetails.upgradeAmount) > 0) {
            let web3 = new Web3(
              datas &&
              datas.provider &&
              datas.provider != null &&
              datas.provider != undefined &&
              datas.provider != ""
                ? datas.provider
                : window.ethereum
            );
            var polygonContract = new web3.eth.Contract(
              polygonapi,
              config.polytrustcontract
            );
            let register = await polygonContract.methods
              .upgradeNextLevel()
              .send({
                from: datas.address,
              });
            if (register && register.status && register.transactionHash) {
              toastAlert("success", "Upgraded Successfully", "network");
              setupgradeprogress("false");
              window.location.reload();
            } else {
              setupgradeprogress("false");
              toastAlert("error", "Failed , try again later", "network");
            }
          } else {
            setupgradeprogress("false");
            toastAlert("error", "Insufficient Earnings to Withdraw", "network");
          }
        } else {
          setupgradeprogress("false");
          toastAlert("error", "Join First to proceed Further", "network");
          window.location.href = "/join-now";
        }
      } else {
        setupgradeprogress("false");
        toastAlert("error", "Please select Matic chain network", "network");
      }
    } catch (err) {
      setupgradeprogress("false");
      console.log(err, "======err in claim");
    }
  }

  //****************mynetwork*********************//
  const idchange = (e) => {
    setsearchid(e.target.value);
  };

  async function empty() {}

  return (
    <div>
      <ScrollToTopOnMount />
      <Aboveinfo />
      <Navbar />
      <div className="inner_wrapper bg bluebg">
        <div className="container">
          <div className="dashboard_panel">
            <div className="dasbox">
              <div className="row">
                <div className="col-md-12">
                  <div className="title_flex">
                    <h2 className="inner_title">Dashboard</h2>
                    <div className="wallet_div">
                      <label>Affiliate Link:</label>
                      <div className="inp_grp">
                        <input
                          type="text"
                          className="form-control primary_inp"
                          value={config.Front_URL + "join-now/" + useraddress}
                        />
                        <button
                          type="button"
                          className="round_btn primary_btn"
                          onClick={copyToClipboard}
                        >
                          <img
                            src={require("../assets/images/copy_icon.png")}
                            className="copy_icon"
                            alt="Copy"
                          />
                        </button>
                      </div>
                    </div>
                    {/* <p className="last_login_details">Last Login: 20 Oct 2022  14:40 PM</p> */}
                  </div>
                </div>
              </div>
              <div className="dashboard_top_wrap">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="leftbox">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>
                                    {" "}
                                    {userdetails && userdetails.userid
                                      ? userdetails.userid
                                      : ""}
                                  </h2>
                                  <h4>My ID</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/dash_icon_04.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>{totaluser ? totaluser : 0}</h2>
                                  <h4>All Participants</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/dash_icon_01.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*  <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{gpr1count ? gpr1count : 0}</h2>
                              <h4>Global Royalty 1 Eligible Participants</h4>
                            </div>
                            <div className=""><img src={require("../assets/images/group.png")} className="img-fluid" alt="Copy" /></div>
                          </div>
                        </div>
                      </div>
                    </div>
                     <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{gpr2count ? gpr2count : 0}</h2>
                              <h4>Global Royalty 2 Eligible Participants</h4>
                            </div>
                            <div className=""><img src={require("../assets/images/group.png")} className="img-fluid" alt="Copy" /></div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>
                                    {userdetails &&
                                    userdetails.totalearnedAmount &&
                                    userdetails.totalearnedAmount > 0
                                      ? (
                                          parseFloat(
                                            userdetails.totalearnedAmount
                                          ) / 1e18
                                        ).toFixed(5)
                                      : 0}
                                  </h2>
                                  <h4>Total Claimed Matic</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/dash_icon_02.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>
                                    {userdetails &&
                                    userdetails.directreferral &&
                                    userdetails.directreferral.length > 0
                                      ? userdetails.directreferral.length
                                      : 0}
                                  </h2>
                                  <h4>Direct Referral</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/dash_icon_03.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>
                                    {userdata && userdata.polygonvalue
                                      ? (
                                          parseFloat(userdata.polygonvalue) /
                                          1e18
                                        ).toFixed(3)
                                      : 0}
                                  </h2>
                                  <h4>Wallet Balance</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/d1.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="primary_box dash_box">
                            <div className="dashbox_middle_top">
                              <div>
                                <div>
                                  <h2>
                                    {userdetails && userdetails.currlevel
                                      ? userdetails.currlevel
                                      : 1}
                                  </h2>
                                  <h4>Current Level</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/d2.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                              {/*<div>
                                <div>
                                  <h2>
                                  {userdetails && userdetails.roi_income ? (parseFloat(userdetails.roi_income) / 1e18).toFixed(3) : 0}</h2>
                                  <h4>ROI Income</h4>
                                </div>
                                <div className="right_imgbox"><img src={require("../assets/images/dash_icon_02.png")} className="img-fluid" alt="Copy" /></div>
                              </div>*/}
                              <div>
                                <div>
                                  <h2>
                                    {userdetails &&
                                    userdetails.currlevel &&
                                    userdetails.currlevel == 1
                                      ? "1.5%"
                                      : userdetails &&
                                        userdetails.currlevel &&
                                        userdetails.currlevel == 2
                                      ? "1.5%"
                                      : userdetails &&
                                        userdetails.currlevel &&
                                        userdetails.currlevel == 3
                                      ? "1.5%"
                                      : userdetails &&
                                        userdetails.currlevel &&
                                        userdetails.currlevel == 4
                                      ? "1.5%"
                                      : userdetails &&
                                        userdetails.currlevel &&
                                        userdetails.currlevel > 4
                                      ? "1.5%"
                                      : "0%"}
                                  </h2>
                                  {/* <h4>ROI Percent</h4> */}
                                  <h4>Hi-Lex 30 M</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/d3.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                              <div>
                                <div>
                                  <h2>
                                    {remainingdays && remainingdays > 0
                                      ? remainingdays
                                      : "--"}
                                  </h2>
                                  <h4>Remaining Days</h4>
                                </div>
                                <div className="right_imgbox">
                                  <img
                                    src={require("../assets/images/d4.png")}
                                    className="img-fluid"
                                    alt="Copy"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-lg-12">
                      <div className="primary_box dash_box">
                        <div className="dashbox_middle_top">
                          <div>
                            <div>
                              <h2>{userdetails && userdetails.week_count  ? parseInt(25) - parseInt(userdetails.week_count) : 25 }</h2>
                              <h4>Remaining Weeks</h4>
                            </div>
                            <div className="">
                              <img src={require("../assets/images/dash_icon_05.png")} className="img-fluid" alt="Copy" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>*/}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <div className="rightbox">
                      <div className="primary_box dash_box spacepad">
                        <div className="dashbox_middle_bottom slider">
                          <h2 className="ms-4 your_income">Your Income</h2>
                          {/*<Slider {...buy_item_carousel}>
                            <div>
                              <div className="dashbox_middle_bottom slidebox">
                                <img src={require("../assets/images/logoicon.png")} className="copy_icon" alt="Copy" />
                                <h5>Direct Referral Income</h5>
                                <h2 className="mb-0">{userdetails && userdetails.globalroyality1income && userdetails.globalroyality1income > 0 ? (parseFloat(userdetails.globalroyality1income) / 1e18).toFixed(5) : 0}<span> Matic </span></h2>
                              </div>
                            </div>
                            <div>
                              <div className="dashbox_middle_bottom slidebox">
                                <img src={require("../assets/images/logoicon.png")} className="copy_icon" alt="Copy" />
                                <h5>Indirect Referral Income</h5>
                                <h2 className="mb-0">{userdetails && userdetails.globalroyality2income && userdetails.globalroyality2income > 0 ? (parseFloat(userdetails.globalroyality2income) / 1e18).toFixed(5) : 0} <span> Matic </span></h2>
                              </div>
                            </div>
                            <div>
                              <div className="dashbox_middle_bottom slidebox">
                                <img src={require("../assets/images/logoicon.png")} className="copy_icon" alt="Copy" />
                                <h5>Total Earnings</h5>
                                <h2 className="mb-0">{userdetails && userdetails.totalearnedAmount && userdetails.totalearnedAmount > 0 ? (parseFloat(userdetails.totalearnedAmount) / 1e18).toFixed(5) : 0} <span> Matic </span></h2>
                              </div>
                            </div>
                            <div>
                              <div className="dashbox_middle_bottom slidebox">
                                <img src={require("../assets/images/logoicon.png")} className="copy_icon" alt="Copy" />
                                <h5>Upgraded Amount</h5>
                                <h2 className="mb-0">{userdetails && userdetails.totalearnedAmount && userdetails.totalearnedAmount > 0 ? (parseFloat(userdetails.totalearnedAmount) / 1e18).toFixed(5) : 0} <span> Matic </span></h2>
                              </div>
                            </div>
                          </Slider>*/}
                          <div className="box">
                            <div className="row flx_align">
                              <div className="col-xxl-12">
                                <div className="row">
                                  {localStorage.getItem("account") != owner && (
                                    <div className="col-lg-6">
                                      <div className="dashbox_middle_bottom text-center">
                                        <h5>Upgraded Amount</h5>
                                        <h2 className="mb-0">
                                          {userdetails &&
                                          userdetails.upgradeamt &&
                                          parseFloat(userdetails.upgradeamt) > 0
                                            ? (
                                                parseFloat(
                                                  userdetails.upgradeamt
                                                ) / 1e18
                                              ).toFixed(5)
                                            : 0}{" "}
                                          <span> Matic </span>{" "}
                                        </h2>
                                      </div>
                                    </div>
                                  )}

                                  <div className="col-lg-6">
                                    <div className="dashbox_middle_bottom  text-center">
                                      <h5>Your Earned Amount</h5>
                                      <h2 className="mb-0">
                                        {userdetails && userdetails.earnedAmount
                                          ? (
                                              parseFloat(
                                                userdetails.earnedAmount
                                              ) /
                                                1e18 +
                                              parseFloat(
                                                userdetails.roi_income
                                              ) /
                                                1e18
                                            ).toFixed(5)
                                          : userdetails &&
                                            userdetails.roi_income
                                          ? (
                                              parseFloat(
                                                userdetails.roi_income
                                              ) / 1e18
                                            ).toFixed(5)
                                          : 0}{" "}
                                        <span>Matic </span>{" "}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-xxl-12 mrg_adj">
                                <div className="dashbox_middle_bottom_flex">
                                  <div className="dashbox_middle_bottom left">
                                    {withdrawprogress == "true" ? (
                                      <button className="butn" type="button">
                                        Processing..
                                      </button>
                                    ) : (
                                      <button
                                        className="butn withdraw"
                                        type="button"
                                        onClick={() => claimRewards()}
                                      >
                                        Withdraw
                                      </button>
                                    )}
                                  </div>

                                  {localStorage.getItem("account") != owner && userdetails &&
                                        userdetails.currlevel &&
                                        parseInt(userdetails.currlevel) != 10 && (
                                    <div className="dashbox_middle_bottom">
                                      {/*upgradeprogress == "true" ? (
                                        <button className="butn" type="button">
                                          Processing ..
                                        </button>
                                      ) : (
                                        <button
                                          className="butn Upgrade_btn"
                                          type="button"
                                          onClick={() => upgradenext()}
                                        >
                                          Upgrade
                                        </button>
                                      )*/}
                                      {userdetails &&
                                        userdetails.currlevel &&
                                        parseInt(userdetails.currlevel) != 10 && (
                                          <button
                                            className="butn Upgrade_btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#wallet_modal"
                                          >
                                            Deposit
                                          </button>
                                        )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="primary_box dash_box mt-4">
                        <div className="dashbox_middle_bottom">
                          <h2>Binary Autopool Income</h2>
                          <div className="plan_wrapper">
                            <div className="buy_item">
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <div>
                                    <h2>10</h2>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L1</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Silver</span></div>
                                </div>
                                {/*<div>
                              <h4>Eligible Income</h4>
                              <h5>0.35 BNB</h5>
                            </div>*/}
                              </div>
                            </div>

                            <div
                              className={
                                userdetails &&
                                userdetails.currlevel &&
                                parseInt(userdetails.currlevel) == 2
                                  ? "buy_item blue"
                                  : userdetails &&
                                    userdetails.currlevel &&
                                    parseInt(userdetails.currlevel) == 1
                                  ? "buy_item buy_item_disabled yellow"
                                  : userdetails && userdetails.currlevel < 2
                                  ? "buy_item buy_item_disabled yellow"
                                  : "buy_item"
                              }
                            >
                              <div className="buy_item_inner yellow">
                                <div className="buy_count">
                                  <div>
                                    <h2>20</h2>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L2</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Gold</span></div>
                                </div>
                                <div>
                                 {/* <div>
                                    {userdetails &&
                                      userdetails.currlevel < 2 && (
                                        <h4>Missed Earnings</h4>
                                      )}
                                    {userdetails &&
                                    userdetails.currlevel < 2 &&
                                    userdetails.l2_missedearnings &&
                                    userdetails.l2_missedearnings > 0 ? (
                                      <h5>
                                        {(
                                          parseFloat(
                                            userdetails.l2_missedearnings
                                          ) / 1e18
                                        ).toFixed(6)}{" "}
                                        Matic
                                      </h5>
                                    ) : (
                                      userdetails &&
                                      userdetails.currlevel < 2 && (
                                        <h5>0 Matic</h5>
                                      )
                                    )}
                                  </div>*/}
                                  {userdetails &&
                                  userdetails.currlevel < 2 &&
                                  parseInt(userdetails.currlevel) + 1 == 2 ? (
                                    <div>
                                      <h4>Upgraded Pending :</h4>
                                      <h5>
                                        {(
                                          parseFloat(20) -
                                          parseFloat(
                                            userdetails.upgradePending
                                          ) /
                                            1e18
                                        ).toFixed(5)}
                                      </h5>
                                    </div>
                                  ) : (
                                    userdetails &&
                                    userdetails.currlevel < 2 && (
                                      <div>
                                        <h4>level value :</h4>
                                        <h5>20</h5>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className={
                                userdetails &&
                                userdetails.currlevel &&
                                parseInt(userdetails.currlevel) == 3
                                  ? "buy_item blue"
                                  : userdetails &&
                                    userdetails.currlevel &&
                                    parseInt(userdetails.currlevel) == 2
                                  ? "buy_item buy_item_disabled yellow"
                                  : userdetails && userdetails.currlevel < 3
                                  ? "buy_item buy_item_disabled"
                                  : "buy_item"
                              }
                            >
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <div>
                                    <h2>40</h2>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L3</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Diamond</span></div>
                                </div>
                                <div>
                                 {/* <div>
                                    {userdetails &&
                                      userdetails.currlevel < 3 && (
                                        <h4>Missed Earnings</h4>
                                      )}
                                    {userdetails &&
                                    userdetails.currlevel < 3 &&
                                    userdetails.l3_missedearnings &&
                                    userdetails.l3_missedearnings > 0 ? (
                                      <h5>
                                        {(
                                          parseFloat(
                                            userdetails.l3_missedearnings
                                          ) / 1e18
                                        ).toFixed(6)}{" "}
                                        Matic
                                      </h5>
                                    ) : (
                                      userdetails &&
                                      userdetails.currlevel < 3 && (
                                        <h5>0 Matic</h5>
                                      )
                                    )}
                                  </div>*/}
                                  {userdetails &&
                                  userdetails.currlevel < 3 &&
                                  parseInt(userdetails.currlevel) + 1 == 3 ? (
                                    <div>
                                      <h4>Upgraded Pending :</h4>
                                      <h5>
                                        {" "}
                                        {(
                                          parseFloat(40) -
                                          parseFloat(
                                            userdetails.upgradePending
                                          ) /
                                            1e18
                                        ).toFixed(5)}{" "}
                                      </h5>
                                    </div>
                                  ) : (
                                    userdetails &&
                                    userdetails.currlevel < 3 && (
                                      <div>
                                        <h4>level value :</h4>
                                        <h5>40</h5>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                userdetails &&
                                userdetails.currlevel &&
                                parseInt(userdetails.currlevel) == 4
                                  ? "buy_item blue"
                                  : userdetails &&
                                    userdetails.currlevel &&
                                    parseInt(userdetails.currlevel) == 3
                                  ? "buy_item buy_item_disabled yellow"
                                  : userdetails && userdetails.currlevel < 4
                                  ? "buy_item buy_item_disabled"
                                  : "buy_item"
                              }
                            >
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <div>
                                    <h2>160</h2>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L4</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Platinum</span></div>
                                </div>
                                <div>
                                 {/* <div>
                                    {userdetails &&
                                      userdetails.currlevel < 4 && (
                                        <h4>Missed Earnings</h4>
                                      )}
                                    {userdetails &&
                                    userdetails.currlevel < 4 &&
                                    userdetails.l4_missedearnings &&
                                    userdetails.l4_missedearnings > 0 ? (
                                      <h5>
                                        {(
                                          parseFloat(
                                            userdetails.l4_missedearnings
                                          ) / 1e18
                                        ).toFixed(6)}{" "}
                                        Matic
                                      </h5>
                                    ) : (
                                      userdetails &&
                                      userdetails.currlevel < 4 && (
                                        <h5>0 Matic</h5>
                                      )
                                    )}
                                  </div>*/}
                                  {userdetails &&
                                  userdetails.currlevel < 4 &&
                                  parseInt(userdetails.currlevel) + 1 == 4 ? (
                                    <div>
                                      <h4>Upgraded Pending :</h4>
                                      <h5>
                                        {" "}
                                        {(
                                          parseFloat(160) -
                                          parseFloat(
                                            userdetails.upgradePending
                                          ) /
                                            1e18
                                        ).toFixed(5)}{" "}
                                      </h5>
                                    </div>
                                  ) : (
                                    userdetails &&
                                    userdetails.currlevel < 4 && (
                                      <div>
                                        <h4>level value :</h4>
                                        <h5> 160 </h5>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                userdetails &&
                                userdetails.currlevel &&
                                parseInt(userdetails.currlevel) == 5
                                  ? "buy_item blue"
                                  : userdetails &&
                                    userdetails.currlevel &&
                                    parseInt(userdetails.currlevel) == 4
                                  ? "buy_item buy_item_disabled yellow"
                                  : userdetails && userdetails.currlevel < 5
                                  ? "buy_item buy_item_disabled"
                                  : "buy_item"
                              }
                            >
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <div>
                                    <h2>1280</h2>
                                    <div></div>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L5</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Rhodium</span></div>
                                </div>
                                <div>
                                 {/* <div>
                                    {userdetails &&
                                      userdetails.currlevel < 5 && (
                                        <h4>Missed Earnings</h4>
                                      )}
                                    {userdetails &&
                                    userdetails.currlevel < 5 &&
                                    userdetails.l5_missedearnings &&
                                    userdetails.l5_missedearnings > 0 ? (
                                      <h5>
                                        {(
                                          parseFloat(
                                            userdetails.l5_missedearnings
                                          ) / 1e18
                                        ).toFixed(6)}{" "}
                                        Matic
                                      </h5>
                                    ) : (
                                      userdetails &&
                                      userdetails.currlevel < 5 && (
                                        <h5>0 Matic</h5>
                                      )
                                    )}
                                  </div>*/}
                                  {userdetails &&
                                  userdetails.currlevel < 5 &&
                                  parseInt(userdetails.currlevel) + 1 == 5 ? (
                                    <div>
                                      <h4>Upgraded Pending :</h4>
                                      <h5>
                                        {" "}
                                        {(
                                          parseFloat(1280) -
                                          parseFloat(
                                            userdetails.upgradePending
                                          ) /
                                            1e18
                                        ).toFixed(5)}{" "}
                                      </h5>
                                    </div>
                                  ) : (
                                    userdetails &&
                                    userdetails.currlevel < 5 && (
                                      <div>
                                        <h4>level value :</h4>
                                        <h5> 1280 </h5>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                userdetails &&
                                userdetails.currlevel &&
                                parseInt(userdetails.currlevel) == 6
                                  ? "buy_item blue"
                                  : userdetails &&
                                    userdetails.currlevel &&
                                    parseInt(userdetails.currlevel) == 5
                                  ? "buy_item buy_item_disabled yellow"
                                  : userdetails && userdetails.currlevel < 6
                                  ? "buy_item buy_item_disabled"
                                  : "buy_item"
                              }
                            >
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <div>
                                    <h2>20480</h2>
                                    <div></div>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L6</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Iridium</span></div>
                                </div>
                                <div>
                                 {/* <div>
                                    {userdetails &&
                                      userdetails.currlevel < 6 && (
                                        <h4>Missed Earnings</h4>
                                      )}
                                    {userdetails &&
                                    userdetails.currlevel < 6 &&
                                    userdetails.l6_missedearnings &&
                                    userdetails.l6_missedearnings > 0 ? (
                                      <h5>
                                        {(
                                          parseFloat(
                                            userdetails.l6_missedearnings
                                          ) / 1e18
                                        ).toFixed(6)}{" "}
                                        Matic
                                      </h5>
                                    ) : (
                                      userdetails &&
                                      userdetails.currlevel < 6 && (
                                        <h5>0 Matic</h5>
                                      )
                                    )}
                                  </div>*/}
                                  {userdetails &&
                                  userdetails.currlevel < 6 &&
                                  parseInt(userdetails.currlevel) + 1 == 6 ? (
                                    <div>
                                      <h4>Upgraded Pending :</h4>
                                      <h5>
                                        {" "}
                                        {(
                                          parseFloat(20480) -
                                          parseFloat(
                                            userdetails.upgradePending
                                          ) /
                                            1e18
                                        ).toFixed(5)}{" "}
                                      </h5>
                                    </div>
                                  ) : (
                                    userdetails &&
                                    userdetails.currlevel < 6 && (
                                      <div>
                                        <h4>level value :</h4>
                                        <h5> 20480 </h5>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                userdetails &&
                                userdetails.currlevel &&
                                parseInt(userdetails.currlevel) == 7
                                  ? "buy_item blue"
                                  : userdetails &&
                                    userdetails.currlevel &&
                                    parseInt(userdetails.currlevel) == 6
                                  ? "buy_item buy_item_disabled yellow"
                                  : userdetails && userdetails.currlevel < 7
                                  ? "buy_item buy_item_disabled"
                                  : "buy_item"
                              }
                            >
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <div>
                                    <h2>20480</h2>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L7</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Osmium</span></div>
                                </div>
                                <div>
                                 {/* <div>
                                    {userdetails &&
                                      userdetails.currlevel < 7 && (
                                        <h4>Missed Earnings</h4>
                                      )}
                                    {userdetails &&
                                    userdetails.currlevel < 7 &&
                                    userdetails.l7_missedearnings &&
                                    userdetails.l7_missedearnings > 0 ? (
                                      <h5>
                                        {(
                                          parseFloat(
                                            userdetails.l7_missedearnings
                                          ) / 1e18
                                        ).toFixed(6)}{" "}
                                        Matic
                                      </h5>
                                    ) : (
                                      userdetails &&
                                      userdetails.currlevel < 7 && (
                                        <h5>0 Matic</h5>
                                      )
                                    )}
                                  </div> */}
                                  {userdetails &&
                                  userdetails.currlevel < 7 &&
                                  parseInt(userdetails.currlevel) + 1 == 7 ? (
                                    <div>
                                      <h4>Upgraded Pending :</h4>
                                      <h5>
                                        {" "}
                                        {(
                                          parseFloat(20480) -
                                          parseFloat(
                                            userdetails.upgradePending
                                          ) /
                                            1e18
                                        ).toFixed(5)}{" "}
                                      </h5>
                                    </div>
                                  ) : (
                                    userdetails &&
                                    userdetails.currlevel < 7 && (
                                      <div>
                                        <h4>level value :</h4>
                                        <h5> 20480 </h5>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                userdetails &&
                                userdetails.currlevel &&
                                parseInt(userdetails.currlevel) == 8
                                  ? "buy_item blue"
                                  : userdetails &&
                                    userdetails.currlevel &&
                                    parseInt(userdetails.currlevel) == 7
                                  ? "buy_item buy_item_disabled yellow"
                                  : userdetails && userdetails.currlevel < 8
                                  ? "buy_item buy_item_disabled"
                                  : "buy_item"
                              }
                            >
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <div>
                                    <h2>40960</h2>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L8</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Ruthenium</span></div>
                                </div>
                                <div>
                                 {/* <div>
                                    {userdetails &&
                                      userdetails.currlevel < 8 && (
                                        <h4>Missed Earnings</h4>
                                      )}
                                    {userdetails &&
                                    userdetails.currlevel < 8 &&
                                    userdetails.l8_missedearnings &&
                                    userdetails.l8_missedearnings > 0 ? (
                                      <h5>
                                        {(
                                          parseFloat(
                                            userdetails.l8_missedearnings
                                          ) / 1e18
                                        ).toFixed(6)}{" "}
                                        Matic
                                      </h5>
                                    ) : (
                                      userdetails &&
                                      userdetails.currlevel < 8 && (
                                        <h5>0 Matic</h5>
                                      )
                                    )}
                                  </div>*/}
                                  {userdetails &&
                                  userdetails.currlevel < 8 &&
                                  parseInt(userdetails.currlevel) + 1 == 8 ? (
                                    <div>
                                      <h4>Upgraded Pending :</h4>
                                      <h5>
                                        {" "}
                                        {(
                                          parseFloat(40960) -
                                          parseFloat(
                                            userdetails.upgradePending
                                          ) /
                                            1e18
                                        ).toFixed(5)}{" "}
                                      </h5>
                                    </div>
                                  ) : (
                                    userdetails &&
                                    userdetails.currlevel < 8 && (
                                      <div>
                                        <h4>level value :</h4>
                                        <h5> 40960 </h5>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                userdetails &&
                                userdetails.currlevel &&
                                parseInt(userdetails.currlevel) == 9
                                  ? "buy_item blue"
                                  : userdetails &&
                                    userdetails.currlevel &&
                                    parseInt(userdetails.currlevel) == 8
                                  ? "buy_item buy_item_disabled yellow"
                                  : userdetails && userdetails.currlevel < 9
                                  ? "buy_item buy_item_disabled"
                                  : "buy_item"
                              }
                            >
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <div>
                                    <h2>163840</h2>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L9</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Palladium</span></div>
                                </div>
                                <div>
                                 {/* <div>
                                    {userdetails &&
                                      userdetails.currlevel < 9 && (
                                        <h4>Missed Earnings</h4>
                                      )}
                                    {userdetails &&
                                    userdetails.currlevel < 9 &&
                                    userdetails.l9_missedearnings &&
                                    userdetails.l9_missedearnings > 0 ? (
                                      <h5>
                                        {(
                                          parseFloat(
                                            userdetails.l9_missedearnings
                                          ) / 1e19
                                        ).toFixed(6)}{" "}
                                        Matic
                                      </h5>
                                    ) : (
                                      userdetails &&
                                      userdetails.currlevel < 9 && (
                                        <h5>0 Matic</h5>
                                      )
                                    )}
                                  </div>*/}
                                  {userdetails &&
                                  userdetails.currlevel < 9 &&
                                  parseInt(userdetails.currlevel) + 1 == 9 ? (
                                    <div>
                                      <h4>Upgraded Pending :</h4>
                                      <h5>
                                        {" "}
                                        {(
                                          parseFloat(163840) -
                                          parseFloat(
                                            userdetails.upgradePending
                                          ) /
                                            1e19
                                        ).toFixed(5)}{" "}
                                      </h5>
                                    </div>
                                  ) : (
                                    userdetails &&
                                    userdetails.currlevel < 9 && (
                                      <div>
                                        <h4>level value :</h4>
                                        <h5> 163840 </h5>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                userdetails &&
                                userdetails.currlevel &&
                                parseInt(userdetails.currlevel) == 10
                                  ? "buy_item blue"
                                  : userdetails &&
                                    userdetails.currlevel &&
                                    parseInt(userdetails.currlevel) == 9
                                  ? "buy_item buy_item_disabled yellow"
                                  : userdetails && userdetails.currlevel < 10
                                  ? "buy_item buy_item_disabled"
                                  : "buy_item"
                              }
                            >
                              <div className="buy_item_inner">
                                <div className="buy_count">
                                  <div>
                                    <h2>1310720</h2>
                                    <h3>Matic</h3>
                                  </div>
                                  {/* <p className="mb-0">L10</p> */}
                                  <div class="ribbon ribbon-top-right"><span>Indium</span></div>
                                </div>
                                <div>
                                 {/* <div>
                                    {userdetails &&
                                      userdetails.currlevel < 10 && (
                                        <h4>Missed Earnings</h4>
                                      )}
                                    {userdetails &&
                                    userdetails.currlevel < 10 &&
                                    userdetails.l10_missedearnings &&
                                    userdetails.l10_missedearnings > 0 ? (
                                      <h5>
                                        {(
                                          parseFloat(
                                            userdetails.l10_missedearnings
                                          ) / 1e18
                                        ).toFixed(6)}{" "}
                                        Matic
                                      </h5>
                                    ) : (
                                      userdetails &&
                                      userdetails.currlevel < 10 && (
                                        <h5>0 Matic</h5>
                                      )
                                    )}
                                  </div>*/}
                                  {userdetails &&
                                  userdetails.currlevel < 10 &&
                                  parseInt(userdetails.currlevel) + 1 == 10 ? (
                                    <div>
                                      <h4>Upgraded Pending :</h4>
                                      <h5>
                                        {" "}
                                        {(
                                          parseFloat(1310720) -
                                          parseFloat(
                                            userdetails.upgradePending
                                          ) /
                                            1e18
                                        ).toFixed(5)}{" "}
                                      </h5>
                                    </div>
                                  ) : (
                                    userdetails &&
                                    userdetails.currlevel < 10 && (
                                      <div>
                                        <h4>level value :</h4>
                                        <h5> 1310720 </h5>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="primary_box dash_box tree mt-4">
                        <div class="accordion" id="accordionExample">
                          <div class="wholebox">
                            <div class="boxctn">
                              <h5
                                class="collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#box5"
                                aria-expanded="false"
                                aria-controls="box"
                              >
                                Genealogy Tree
                              </h5>
                              <div
                                id="box5"
                                class="collapse"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="dashbox_middle_bottom">
                                  <div className="inp_grp dash_user_search_box">
                                    <input
                                      type="text"
                                      className="form-control primary_inp"
                                      value={searchid}
                                      onChange={idchange}
                                      placeholder="Search By Id"
                                    />
                                    <button
                                      type="button"
                                      className="butn"
                                      onClick={() => searchbyid()}
                                    >
                                      Search
                                    </button>
                                  </div>
                                  {!treeprogress ? (
                                    <div>
                                      <AnimatedTree
                                        data={selected}
                                        height={1200}
                                        width={800}
                                        gProps={{
                                          className: "node",
                                          onClick: handleClick,
                                        }}
                                        nodeShape="image"
                                        nodeProps={{
                                          href:
                                            config.Front_URL +
                                            "Images/tree_icon.png",
                                          width: "13",
                                          height: "21",
                                        }}
                                        margins={{
                                          top: 50,
                                          bottom: 50,
                                          left: 80,
                                          right: 80,
                                        }}
                                        textProps={{ dx: -16, dy: 25 }}
                                      />
                                    </div>
                                  ) : (
                                    <div>
                                      <center>
                                        <p className="text-danger mb-1">
                                          Processing....,Please Wait
                                        </p>
                                      </center>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="primary_box dash_box mt-4">
                        <div className="dashbox_middle_bottom">
                          <h2>Your Network</h2>
                          <div className="plan_income_table_div">
                            <div className="table-responsive">
                              <table className="table plan_income_table mt-0">
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>ID</th>
                                    <th>Address</th>
                                    <th>Level</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {mynetwork &&
                                    mynetwork.length > 0 &&
                                    mynetwork.map((item, index) => {
                                      return (
                                        <tr>
                                          <td>{parseInt(index) + 1}</td>
                                          <td>{item.userid}</td>
                                          <td>{item.address}</td>
                                          <td>{item.level}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="primary_box dash_box mt-4">
                        <div className="dashbox_middle_bottom">
                          <h2>Your Level Income</h2>
                          <div className='plan_income_table_div'>
                            <div className='table-responsive'>
                              <table className='table plan_income_table mt-0'>
                                <thead>
                                  <tr>
                                    <th>Level</th>
                                    <th>No of Users</th>
                                    <th>Income (Matic)</th>
                                  </tr>
                                </thead>
                                {!levelload ?
                                  <tbody>
                                    <tr>
                                      <td>L1</td>
                                      <td>{levelcounts && levelcounts.l1count ? levelcounts.l1count : 0}</td>
                                      <td>{levelcounts && levelcounts.l1count ? (parseFloat(levelcounts.l1count) * 10).toFixed(5) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td>L2</td>
                                      <td>{levelcounts && levelcounts.l2count ? levelcounts.l2count : 0}</td>
                                      <td>{levelcounts && levelcounts.l2count ? (parseFloat(levelcounts.l2count) * 20).toFixed(5) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td>L3</td>
                                      <td>{levelcounts && levelcounts.l3count ? levelcounts.l3count : 0}</td>
                                      <td>{levelcounts && levelcounts.l3count ? (parseFloat(levelcounts.l3count) * 40).toFixed(5) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td>L4</td>
                                      <td>{levelcounts && levelcounts.l4count ? levelcounts.l4count : 0}</td>
                                      <td>{levelcounts && levelcounts.l4count ? (parseFloat(levelcounts.l4count) * 160).toFixed(5) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td>L5</td>
                                      <td>{levelcounts && levelcounts.l5count ? levelcounts.l5count : 0}</td>
                                      <td>{levelcounts && levelcounts.l5count ? (parseFloat(levelcounts.l5count) * 1280).toFixed(5) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td>L6</td>
                                      <td>{levelcounts && levelcounts.l6count ? levelcounts.l6count : 0}</td>
                                      <td>{levelcounts && levelcounts.l6count ? (parseFloat(levelcounts.l6count) * 20480).toFixed(5) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td>L7</td>
                                      <td>{levelcounts && levelcounts.l7count ? levelcounts.l7count : 0}</td>
                                      <td>{levelcounts && levelcounts.l7count ? (parseFloat(levelcounts.l7count) * 20480).toFixed(5) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td>L8</td>
                                      <td>{levelcounts && levelcounts.l8count ? levelcounts.l8count : 0}</td>
                                      <td>{levelcounts && levelcounts.l8count ? (parseFloat(levelcounts.l8count) * 40960).toFixed(5) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td>L9</td>
                                      <td>{levelcounts && levelcounts.l9count ? levelcounts.l9count : 0}</td>
                                      <td>{levelcounts && levelcounts.l9count ? (parseFloat(levelcounts.l9count) * 163840).toFixed(5) : 0}</td>
                                    </tr>
                                    <tr>
                                      <td>L10</td>
                                      <td>{levelcounts && levelcounts.l10count ? levelcounts.l10count : 0}</td>
                                      <td>{levelcounts && levelcounts.l10count ? (parseFloat(levelcounts.l10count) * 1310720).toFixed(5) : 0}</td>
                                    </tr>

                                    <tr>
                                      <td colspan={3}>
                                        <h4 className="text-center">{"Total Earned Matic : " +
                                          ((levelcounts && levelcounts.l1count ? (parseFloat(levelcounts.l1count) * 10) : 0) +
                                            (levelcounts && levelcounts.l2count ? (parseFloat(levelcounts.l2count) * 20) : 0) +
                                            (levelcounts && levelcounts.l3count ? (parseFloat(levelcounts.l3count) * 40) : 0) +
                                            (levelcounts && levelcounts.l4count ? (parseFloat(levelcounts.l4count) * 160) : 0) +
                                            (levelcounts && levelcounts.l5count ? (parseFloat(levelcounts.l5count) * 1280) : 0) +
                                            (levelcounts && levelcounts.l6count ? (parseFloat(levelcounts.l6count) * 20480) : 0) +
                                            (levelcounts && levelcounts.l7count ? (parseFloat(levelcounts.l7count) * 20480) : 0) +
                                            (levelcounts && levelcounts.l8count ? (parseFloat(levelcounts.l8count) * 40960) : 0) +
                                            (levelcounts && levelcounts.l9count ? (parseFloat(levelcounts.l9count) * 163840) : 0) +
                                            (levelcounts && levelcounts.l10count ? (parseFloat(levelcounts.l10count) * 1310720) : 0)).toFixed(5)
                                        } </h4>
                                      </td>
                                    </tr>
                                  </tbody>
                                  :
                                  ""
                                }
                              </table>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="primary_box dash_box mt-4">
                        <div className="dashbox_middle_bottom">
                          <h2>Matrix Upgrade Income </h2>
                          <div className="plan_income_table_div">
                            <div className="table-responsive">
                              <table className="table plan_income_table mt-0">
                                <thead>
                                  <tr>
                                    <th>Level</th>
                                    <th>Upgraded User</th>
                                    <th>Waiting for Upgrade</th>
                                    {/*<th>Missed Earnings</th>*/}
                                  </tr>
                                </thead>
                                {!planprogress && (
                                  <tbody>
                                    {/*  <tr>
                                <td>L1</td>
                                <td>{planrange && planrange.l1range && planrange.l1range.greator ? planrange.l1range.greator : 0}</td>
                                <td>{planrange && planrange.l1range && planrange.l1range.smaller ? planrange.l1range.smaller : 0}</td>
                                <td>{planrange && planrange.l1missed ? parseFloat(planrange.l1missed).toFixed(5) : 0 }</td>
                              </tr> */}
                                    <tr>
                                      <td>L2</td>
                                      <td>
                                        {planrange &&
                                        planrange.l2range &&
                                        planrange.l2range.greator
                                          ? planrange.l2range.greator
                                          : 0}
                                      </td>
                                      <td>
                                        {planrange &&
                                        planrange.l2range &&
                                        planrange.l2range.smaller
                                          ? planrange.l2range.smaller
                                          : 0}
                                      </td>
                                     {/* <td>
                                        {planrange && planrange.l2missed
                                          ? parseFloat(
                                              planrange.l2missed
                                            ).toFixed(5)
                                          : 0}
                                      </td>*/}
                                    </tr>
                                    <tr>
                                      <td>L3</td>
                                      <td>
                                        {planrange &&
                                        planrange.l3range &&
                                        planrange.l3range.greator
                                          ? planrange.l3range.greator
                                          : 0}
                                      </td>
                                      <td>
                                        {planrange &&
                                        planrange.l3range &&
                                        planrange.l3range.smaller
                                          ? planrange.l3range.smaller
                                          : 0}
                                      </td>
                                      {/*<td>
                                        {planrange && planrange.l3missed
                                          ? parseFloat(
                                              planrange.l3missed
                                            ).toFixed(5)
                                          : 0}
                                      </td>*/}
                                    </tr>
                                    <tr>
                                      <td>L4</td>
                                      <td>
                                        {planrange &&
                                        planrange.l4range &&
                                        planrange.l4range.greator
                                          ? planrange.l4range.greator
                                          : 0}
                                      </td>
                                      <td>
                                        {planrange &&
                                        planrange.l4range &&
                                        planrange.l4range.smaller
                                          ? planrange.l4range.smaller
                                          : 0}
                                      </td>
                                     {/* <td>
                                        {planrange && planrange.l4missed
                                          ? parseFloat(
                                              planrange.l4missed
                                            ).toFixed(5)
                                          : 0}
                                      </td>*/}
                                    </tr>
                                    <tr>
                                      <td>L5</td>
                                      <td>
                                        {planrange &&
                                        planrange.l5range &&
                                        planrange.l5range.greator
                                          ? planrange.l5range.greator
                                          : 0}
                                      </td>
                                      <td>
                                        {planrange &&
                                        planrange.l5range &&
                                        planrange.l5range.smaller
                                          ? planrange.l5range.smaller
                                          : 0}
                                      </td>
                                     {/* <td>
                                        {planrange && planrange.l5missed
                                          ? parseFloat(
                                              planrange.l5missed
                                            ).toFixed(5)
                                          : 0}
                                      </td>*/}
                                    </tr>
                                    <tr>
                                      <td>L1</td>
                                      <td>
                                        {planrange &&
                                        planrange.l6range &&
                                        planrange.l6range.greator
                                          ? planrange.l6range.greator
                                          : 0}
                                      </td>
                                      <td>
                                        {planrange &&
                                        planrange.l6range &&
                                        planrange.l6range.smaller
                                          ? planrange.l6range.smaller
                                          : 0}
                                      </td>
                                      {/*<td>
                                        {planrange && planrange.l6missed
                                          ? parseFloat(
                                              planrange.l6missed
                                            ).toFixed(5)
                                          : 0}
                                      </td>*/}
                                    </tr>
                                    <tr>
                                      <td>L2</td>
                                      <td>
                                        {planrange &&
                                        planrange.l7range &&
                                        planrange.l7range.greator
                                          ? planrange.l7range.greator
                                          : 0}
                                      </td>
                                      <td>
                                        {planrange &&
                                        planrange.l7range &&
                                        planrange.l7range.smaller
                                          ? planrange.l7range.smaller
                                          : 0}
                                      </td>
                                      {/*<td>
                                        {planrange && planrange.l7missed
                                          ? parseFloat(
                                              planrange.l7missed
                                            ).toFixed(5)
                                          : 0}
                                      </td>*/}
                                    </tr>
                                    <tr>
                                      <td>L3</td>
                                      <td>
                                        {planrange &&
                                        planrange.l8range &&
                                        planrange.l8range.greator
                                          ? planrange.l8range.greator
                                          : 0}
                                      </td>
                                      <td>
                                        {planrange &&
                                        planrange.l8range &&
                                        planrange.l8range.smaller
                                          ? planrange.l8range.smaller
                                          : 0}
                                      </td>
                                      {/*<td>
                                        {planrange && planrange.l8missed
                                          ? parseFloat(
                                              planrange.l8missed
                                            ).toFixed(5)
                                          : 0}
                                      </td>*/}
                                    </tr>
                                    <tr>
                                      <td>L4</td>
                                      <td>
                                        {planrange &&
                                        planrange.l9range &&
                                        planrange.l9range.greator
                                          ? planrange.l9range.greator
                                          : 0}
                                      </td>
                                      <td>
                                        {planrange &&
                                        planrange.l9range &&
                                        planrange.l9range.smaller
                                          ? planrange.l9range.smaller
                                          : 0}
                                      </td>
                                      {/*<td>
                                        {planrange && planrange.l9missed
                                          ? parseFloat(
                                              planrange.l9missed
                                            ).toFixed(5)
                                          : 0}
                                      </td>*/}
                                    </tr>
                                    <tr>
                                      <td>L5</td>
                                      <td>
                                        {planrange &&
                                        planrange.l10range &&
                                        planrange.l10range.greator
                                          ? planrange.l10range.greator
                                          : 0}
                                      </td>
                                      <td>
                                        {planrange &&
                                        planrange.l10range &&
                                        planrange.l10range.smaller
                                          ? planrange.l10range.smaller
                                          : 0}
                                      </td>
                                      {/*<td>
                                        {planrange && planrange.l10missed
                                          ? parseFloat(
                                              planrange.l10missed
                                            ).toFixed(5)
                                          : 0}
                                      </td>*/}
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div
        className="modal fade primary_modal"
        id="wallet_modal"
        tabIndex={-1}
        aria-labelledby="walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="walletModalLabel">
                Deposit
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label for="exampleFormControlInput1" class="form-label">
                Invest Amount
              </label>
              <input
                type="Number"
                class="form-control primary_inp"
                id="exampleFormControlInput1"
                placeholder="Enter Invest Amount"
                value={depositamt}
                onChange={depositamtchange}
                min="0"
              />
              <div className="mt-3">
                <button
                  className="primary_btn"
                  type="button"
                  onClick={depositLoad == false ? deposit_busd : () => empty()}
                >
                  {depositLoad == false
                    ? "Submit"
                    : "Processing..Please Wait.."}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
